import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import Datetime from "react-datetime";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CommonConfig } from "CommonConfig";
import { InputText } from "primereact/inputtext";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button } from "primereact/button";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import APIConstant from "utils/constant";
import api from "utils/api";
import { MultiSelect } from "primereact/multiselect";
import _ from "lodash";
import cogoToast from "cogo-toast";

const initialNameState = {
  name: "",
  error: false,
  errorText: "",
};

const initialNameArrayState = {
  name: [],
  error: false,
  errorText: "",
};

const Stock = (props) => {
  const [userId, setUserId] = useState("");
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    CommonConfig.dataTableConfig.rowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonConfig.dataTableConfig.rowsPerPage
  );
  const [stockId, setStockId] = useState("");
  const [inwardStockList, setInwardStockList] = useState([]);
  const [outwardStockList, setOutwardStockList] = useState([]);
  const [deleteStockDialog, setDeleteStockDialog] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [subWareHouseList, setSubWareHouseList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [returnItemDialog, setReturnItemDialog] = useState(false);
  const [returnFromList, setReturnFromList] = useState([]);
  const [returnToList, setReturnToList] = useState([]);
  const [returnData, setReturnData] = useState({
    stockId: "",
    returnId: "",
    inwardId: "",
    returnType: "",
    from: "",
    fromErr: false,
    fromHelperText: "",
    to: "",
    toErr: false,
    toErrText: "",
    returnItems: [],
    returnDate: "",
    reason: "",
    reasonErr: false,
    reasonErrText: "",
    remark: "",
  });
  const [reasonName, setReasonName] = useState("");
  const [AddReasonDialog, setAddReasonDialog] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      getStockList(data);
      getSubWareHouseList(data);
      getVendorList(data);
      getClientList(data);
      getEquipmentList(data);
      getReasonList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getVendorList = async (UserId) => {
    let params = {
      UserId: UserId,
      UserType: "Vendor",
    };
    api
      .post(APIConstant.path.GetNotVerifiedNetworkByUserType, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              label: data.Name,
              value: data._id,
            })
          );

          setVendorList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getClientList = async (UserId) => {
    let params = {
      UserId: UserId,
      UserType: "Client",
    };
    api
      .post(APIConstant.path.GetNotVerifiedNetworkByUserType, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              label: data.Name,
              value: data._id,
            })
          );

          setClientList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getReasonList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetReasonMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          setReasonList(
            response.Data.map((data) => ({
              ...data,
              label: data.ReasonName,
              value: data._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const deleteStock = () => {
    let params = {
      UserId: userId,
      StockId: stockId,
    };
    api
      .post(APIConstant.path.DeleteStock, params)
      .then((response) => {
        if (response.Success === 1) {
          cogoToast.success(response.Message);
          getStockList(userId);
          handleClose("DeleteMasterType");
        } else {
          cogoToast.error(response.Message);
        }
      })
      .catch((err) => {});
  };

  const getStockList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetStockList, params)
      .then((response) => {
        if (response.Success === 1) {
          let InwardList = [];
          let OutwardList = [];
          response.Data.map((data) => {
            data.DateTime = moment(data.DateTime).format("DD/MM/YYYY(hh:mm) a");
            if (data.Type === "Inward") {
              InwardList.push(data);
            } else {
              OutwardList.push(data);
            }
          });
          setInwardStockList(InwardList);
          setOutwardStockList(OutwardList);
        }
      })
      .catch((err) => {});
  };

  const getStockById = async (StockId) => {
    let params = {
      UserId: userId,
      StockId,
    };
    api
      .post(APIConstant.path.GetStockById, params)
      .then(async (response) => {
        if (response.Success === 1) {
          let data = response.Data;
          let returnType = "Inward";
          let returnId = await generateReturnId(data.Type);
          if (data.Type === "Inward") {
            setReturnFromList(subWareHouseList);
            setReturnToList(vendorList);
          } else {
            setReturnToList(subWareHouseList);
            returnType = data.OutwardType;
            if (data.OutwardType === "Sell") {
              setReturnFromList(clientList);
            } else if (data.OutwardType === "Machinery") {
              setReturnFromList(equipmentList);
            } else {
              setReturnFromList(subWareHouseList);
            }
          }
          let items = data.ConvertedData.map((item) => ({
            _id: item._id,
            ItemId: item.ItemId,
            Item: item.Item,
            OriginalUnitId: item.OriginalUnitId,
            UnitId: item.UnitId,
            Unit: item.Unit,
            Quantity: item.Quantity,
            ReturnQuantity: 0,
            Cost: item.Cost,
            CostPrice: item.Price,
            ReturnPrice: 0,
          }));
          data.RawData.map((item) => {
            if (!item.ProcessId) {
              items.push({
                _id: item._id,
                ItemId: item.ItemId,
                Item: item.Item,
                OriiginalUnitId: item.OriginalUnitId,
                UnitId: item.UnitId,
                Unit: item.Unit,
                Quantity: item.Quantity,
                ReturnQuantity: 0,
                Cost: item.Cost,
                CostPrice: item.Price,
                ReturnPrice: 0,
              });
            }
          });
          setReturnData({
            stockId,
            returnId,
            inwardId: data.IdNo,
            from: data.To,
            to: data.From,
            returnDate: "",
            returnItems: items,
            returnType,
            reason: "",
            remark: "",
          });
        }
      })
      .catch((err) => {});
  };

  const getSubWareHouseList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetSubWareHouseList, params)
      .then((response) => {
        if (response.Success === 1) {
          setSubWareHouseList(
            response.Data.map((subWareHouse) => ({
              label: subWareHouse.Name,
              value: subWareHouse._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const getEquipmentList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetEquipmentMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          setEquipmentList(
            response.Data.map((equipment) => ({
              label: equipment.EquipmentName,
              value: equipment._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const generateReturnId = async (type) => {
    try {
      let params = {
        UserId: userId,
        Type: type === "Inward" ? "I" : "O",
      };

      const response = await api.post(
        APIConstant.path.GenerateUniqueReturnId,
        params
      );

      if (response.Success === 1) {
        return response.generatedID;
      } else {
        throw new Error("Failed to generate ID");
      }
    } catch (err) {
      console.error("Error generating return ID:", err);
      return null;
    }
  };

  const addReason = () => {
    let params = {
      ReasonName: reasonName,
      UserID: userId,
    };
    api.post(APIConstant.path.AddUpdateReasonMaster, params).then((res) => {
      if (res.Success == 1) {
        handleClose("AddReason");
        getReasonList(userId);
        cogoToast.success(res.Message);
      } else {
        cogoToast.error(res.Message);
      }
    });
  };

  const returnItem = async (e) => {
    e.preventDefault();
    if (!validateReturnForm()) {
      return;
    }
    const {
      returnId,
      returnType,
      inwardId,
      from,
      reason,
      remark,
      to,
      returnDate,
      returnItems,
    } = returnData;
    let params = {
      UserId: userId,
      ReturnId: returnId,
      StockId: inwardId,
      StockType: returnType,
      FromId: from,
      From: returnFromList.filter((data) => data.value === from)[0].label,
      ToId: to,
      To: returnToList.filter((data) => data.value === to)[0].label,
      Reason: reason,
      Remark: remark,
      ReturnedDate: returnDate,
      ReturnedItems: returnItems,
    };
    api.post(APIConstant.path.AddReturnedItems, params).then((res) => {
      if (res.Success == 1) {
        handleClose("returnItemDialog");
        cogoToast.success(res.Message);
      } else {
        cogoToast.error(res.Message);
      }
    });
  };

  const validateReturnForm = () => {
    let isValid = true;
    if (CommonConfig.isObjectEmpty(returnData.reason)) {
      isValid = false;
      setReturnData((prevState) => ({
        ...prevState,
        reasonErr: true,
        reasonErrText: "Please select a valid reason",
      }));
    }
    if (CommonConfig.isObjectEmpty(returnData.from)) {
      isValid = false;
      setReturnData((prevState) => ({
        ...prevState,
        fromErr: true,
        fromHelperText: "Please select a valid reason",
      }));
    }
    if (CommonConfig.isObjectEmpty(returnData.to)) {
      isValid = false;
      setReturnData((prevState) => ({
        ...prevState,
        toErr: true,
        toErrText: "Please select a valid reason",
      }));
    }
    let checkQuantity = false;
    returnData.returnItems.forEach((returnItem) => {
      if (returnItem.ReturnQuantity >= 0) {
        checkQuantity = true;
      }
    });
    if (!checkQuantity) {
      isValid = false;
      cogoToast.error("Please enter return quantity");
    }

    return isValid;
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  const handleOpen = async (e, type) => {
    if (type === "AddMaster") {
      props.history.push({
        pathname: "/admin/add-stock",
        state: {
          tabValue: tabValue,
          type: "Add",
        },
      });
    } else if (type === "ViewMasterType") {
      props.history.push({
        pathname: "/admin/add-stock",
        state: {
          stockId: e._id,
          tabValue: tabValue,
          type: "View",
        },
      });
    } else if (type === "EditMasterType") {
      props.history.push({
        pathname: "/admin/add-stock",
        state: {
          stockId: e._id,
          tabValue: tabValue,
          type: "Edit",
        },
      });
    } else if (type === "DeleteMasterType") {
      setStockId(e._id);
      setDeleteStockDialog(true);
    } else if (type === "ReturnItem") {
      setStockId(e._id);
      await getStockById(e._id);
      setReturnItemDialog(true);
    } else if (type === "ReasonName") {
      setAddReasonDialog(true);
    }
  };

  const handleClose = (type) => {
    if (type === "DeleteMasterType") {
      setDeleteStockDialog(false);
      setStockId("");
    } else if (type === "returnItemDialog") {
      setReturnData({
        stockId: "",
        returnId: "",
        inwardId: "",
        returnType: "",
        from: "",
        to: "",
        returnItems: [],
        returnDate: "",
        reason: "",
        remark: "",
      });
      setReturnFromList([]);
      setReturnToList([]);
      setReturnItemDialog(false);
      setStockId("");
    } else if (type === "AddReason") {
      setAddReasonDialog(false);
      setReasonName("");
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionReturnTemplate = (data, props) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => handleOpen(data, "ReturnItem")}
      >
        <LoopIcon />
      </div>
    );
  };

  const actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {userPageAccess.includes("View Stock") ? (
          <i
            onClick={(e) => handleOpen(rowData, "ViewMasterType")}
            class="icon-view"
          ></i>
        ) : null}
        {userPageAccess.includes("Edit stock") ? (
          <i
            onClick={(e) => handleOpen(rowData, "EditMasterType")}
            class="icon-editImage"
          ></i>
        ) : null}
        {userPageAccess.includes("Delete Stock") ? (
          <i
            onClick={(e) => handleOpen(rowData, "DeleteMasterType")}
            class="icon-delete"
          ></i>
        ) : null}
      </div>
    );
  };

  const handleChange = (e, type) => {
    if (type === "returnRemark") {
      setReturnData((prevState) => ({ ...prevState, remark: e.target.value }));
    } else if (type === "reason") {
      if (CommonConfig.isEmpty(e?.value)) {
        setReturnData((prevState) => ({
          ...prevState,
          reason: "",
          reasonErr: true,
          reasonErrText: "Please select reason",
        }));
      } else {
        setReturnData((prevState) => ({
          ...prevState,
          reason: e.value,
          reasonErr: false,
          reasonErrText: "",
        }));
      }
    } else if (type === "reasonName") {
      setReasonName(e.target.value);
    }
  };

  const actionBodyReturnData = (rowData, props) => {
    return (
      <TextField
        autoFocus
        margin="dense"
        name="returnQty"
        type="text"
        value={returnData.returnItems[props.rowIndex].ReturnQuantity}
        onChange={(e) => {
          let tempReturnData = { ...returnData };
          let returnItem = returnData.returnItems[props.rowIndex];
          if (
            (/^\d*$/i.test(e.target.value) || e.target.value === "") &&
            e.target.value <= parseFloat(returnItem.Quantity) &&
            e.target.value >= 0
          ) {
            returnItem.ReturnQuantity = e.target.value;
            returnItem.ReturnPrice = e.target.value * returnItem.Cost;
          }
          tempReturnData.returnItems[props.rowIndex] = returnItem;
          setReturnData(tempReturnData);
        }}
        inputProps={{ maxLength: 10 }}
        fullWidth
      />
    );
  };

  return (
    <div className="wayment-owner-dashboard e-weighment-dashoard">
      <div className="main-access-contianer e-main-access">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label="Inward" {...a11yProps(0)} />

            <Tab label="Outward" {...a11yProps(1)} />
          </Tabs>
          <div className="heading-area">
            <div className="heading-area-right">
              <div className="searchbar-area p-as-center">
                <InputText
                  type="search"
                  onInput={(e) => setSearch(e.target.value)}
                  placeholder="Global Search"
                  size="30"
                  style={{ marginRight: 10 }}
                />
                <i className="icon-search"></i>
              </div>
              {userPageAccess.includes("Add Stock") ? (
                <div>
                  <div
                    className="e-add-btn"
                    onClick={(e) => handleOpen(e, "AddMaster")}
                  >
                    <i className="icon-add"></i>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <TabPanel
          className="main-card inward-container"
          value={tabValue}
          index={0}
        >
          <div className="custom-large-wb-table main-access-card-master">
            <DataTable
              className="main-table table-td"
              value={inwardStockList}
              editMode="row"
              dataKey="id"
              responsiveLayout="scroll"
              paginator={true}
              rows={rowsPerPage}
              totalRecords={inwardStockList.length}
              rowsPerPageOptions={rowsPerPageOptions}
              globalFilter={search}
              emptyMessage="No records found"
              responsive={false}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            >
              <Column
                field="Index"
                header="Sr.No"
                body={actionBodyTemplateSrNo}
                style={{ width: "8%" }}
              ></Column>
              <Column
                field="IdNo"
                header="Inward Number"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="InvoiceNumber"
                header="Invoice Number"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="DateTime"
                header="Date and Time"
                style={{ width: "30%" }}
              ></Column>
              <Column
                field="ToName"
                header="Sub-Warehouse"
                style={{ width: "30%" }}
              ></Column>
              <Column
                field="Remark"
                header="Remarks"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="Action"
                header="Action"
                body={actionTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
              <Column
                field="Return Item"
                header="Return Item"
                body={actionReturnTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel
          className="main-card outward-container"
          value={tabValue}
          index={1}
        >
          <div className="custom-large-wb-table main-access-card-master">
            <DataTable
              className="main-table table-td"
              value={outwardStockList}
              editMode="row"
              dataKey="id"
              responsiveLayout="scroll"
              paginator={true}
              rows={rowsPerPage}
              totalRecords={outwardStockList.length}
              rowsPerPageOptions={rowsPerPageOptions}
              globalFilter={search}
              emptyMessage="No records found"
              responsive={false}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            >
              <Column
                field="Index"
                header="Sr.No"
                body={actionBodyTemplateSrNo}
                style={{ width: "8%" }}
              ></Column>
              <Column
                field="IdNo"
                header="Outward Number"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="InvoiceNumber"
                header="Invoice Number"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="DateTime"
                header="Date and Time"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="FromName"
                header="Sub-Warehouse"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="Remark"
                header="Remarks"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="Action"
                header="Action"
                body={actionTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
              <Column
                field="Return Item"
                header="Return Item"
                body={actionReturnTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          </div>
        </TabPanel>

        <Dialog
          open={returnItemDialog}
          onClose={() => handleClose("returnItemDialog")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Return Items</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("returnItemDialog")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <TextField
                  style={{ marginTop: "22px" }}
                  autoFocus
                  margin="dense"
                  name="ReturnId"
                  label="Return ID"
                  type="text"
                  value={returnData.returnId}
                  disabled={true}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <TextField
                  style={{ marginTop: "22px" }}
                  autoFocus
                  margin="dense"
                  name="InwardId"
                  label={tabValue === 0 ? "Inward ID" : "Outward ID"}
                  type="text"
                  value={returnData.inwardId}
                  disabled={true}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={12}>
                <label>
                  Return {tabValue === 1 && `(${returnData.returnType} Type)`}
                </label>

                {tabValue === 1 && (
                  <div>
                    <span>Outward Type</span>{" "}
                    <span>{returnData.returnType}</span>
                  </div>
                )}
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <div className="input-control select p-d-flex">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="from"
                      name="from"
                      value={
                        returnData.from && returnFromList.length
                          ? returnFromList.filter(
                              (y) => y.value == returnData.from
                            )[0]
                          : ""
                      }
                      options={returnFromList}
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      onChange={(e, value) => handleChange(value, "returnFrom")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="from"
                          label="From"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <div className="input-control select p-d-flex">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="from"
                      name="from"
                      value={
                        returnData.to && returnToList.length
                          ? returnToList.filter(
                              (y) => y.value == returnData.to
                            )[0]
                          : ""
                      }
                      options={returnToList}
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      onChange={(e, value) => handleChange(value, "returnTo")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="to"
                          label="To"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <Datetime
                  value={moment(returnData.returnDate)}
                  selected={moment(returnData.returnDate)}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={(e) =>
                    setReturnData((prevState) => ({
                      ...prevState,
                      returnDate: e,
                    }))
                  }
                  closeOnSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ marginTop: "23px" }}
                      label="Date Returned"
                      fullWidth
                    />
                  )}
                />
              </GridItem>

              <GridItem xs={12}>
                <DataTable
                  className="main-table table-td"
                  style={{ height: "auto" }}
                  value={returnData.returnItems}
                  editMode="row"
                  dataKey="id"
                  responsiveLayout="scroll"
                  emptyMessage="No records found"
                  columnResizeMode="fit"
                  scrollable={true}
                >
                  <Column
                    field="Index"
                    header="Sr.No"
                    body={actionBodyTemplateSrNo}
                    style={{ width: "20% !important" }}
                  ></Column>
                  <Column
                    field="Item"
                    header="Items"
                    style={{ width: "20% !important" }}
                  ></Column>
                  <Column
                    field="Unit"
                    header="Unit"
                    style={{ width: "20% !important" }}
                  ></Column>
                  <Column
                    field="Quantity"
                    header="Qty"
                    style={{ width: "20% !important" }}
                  ></Column>
                  <Column
                    field="ReturnQuantity"
                    header="Returned Qty"
                    style={{ width: "20% !important" }}
                    body={(rowData, props) =>
                      actionBodyReturnData(rowData, props)
                    }
                  ></Column>
                  <Column
                    field="CostPrice"
                    header="Cost Price"
                    style={{ width: "20% !important" }}
                  ></Column>
                  <Column
                    field="ReturnPrice"
                    header="Return Price"
                    style={{ width: "20% !important" }}
                  ></Column>
                </DataTable>
              </GridItem>

              <GridItem
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Autocomplete
                  id="reason"
                  name="reason"
                  value={
                    returnData.reason && reasonList.length
                      ? reasonList.filter(
                          (y) => y.value == returnData.reason
                        )[0]
                      : ""
                  }
                  options={reasonList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  onChange={(e, value) => handleChange(value, "reason")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Reason"
                      error={returnData.reasonErr}
                      helperText={returnData.reasonErrText}
                    />
                  )}
                  style={{ width: "90%" }}
                />
                <button
                  className="filter-btn cursor-pointer"
                  onClick={(e) => handleOpen(e, "ReasonName")}
                >
                  <i className="icon-add"></i>
                </button>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="remarks"
                  label="Remarks"
                  type="text"
                  value={returnData.remark}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => handleChange(e, "returnRemark")}
                  fullWidth
                  multiline
                  minRows={2}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("returnItemDialog")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => returnItem(e)}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={AddReasonDialog}
          onClose={() => handleClose("AddReason")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Add Reason</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("AddReason")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <GridContainer>
              <GridItem md={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="Reason"
                  label="Reason Name"
                  type="text"
                  value={reasonName}
                  onChange={(e) => handleChange(e, "reasonName")}
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("AddReason")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => addReason(e)}
              type="submit"
              disabled={!reasonName}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteStockDialog}
          onClose={() => handleClose("DeleteMasterType")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Delete Stock</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("DeleteMasterType")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <h6>Are you sure want to delete?</h6>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("DeleteMasterType")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => deleteStock(e)}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Stock;
