import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import Datetime from "react-datetime";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CommonConfig } from "CommonConfig";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button } from "primereact/button";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import APIConstant from "utils/constant";
import api from "utils/api";
import { MultiSelect } from "primereact/multiselect";
import _ from "lodash";
import cogoToast from "cogo-toast";

const initialNameState = {
  name: "",
  error: false,
  errorText: "",
};

const initialNameArrayState = {
  name: [],
  error: false,
  errorText: "",
};

const AddStock = (props) => {
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [userId, setUserId] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [OutwardTabValue, setOutwardTabValue] = useState(0);
  const [stockId, setStockId] = useState("");
  const [multiplyProcessDialog, setMultiplyProcessDialog] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [inwardNumber, setInwardNumber] = useState(initialNameState);
  const [outwardTo, setOutwardTo] = useState(initialNameState);
  const [outwardToData, setOutwardToData] = useState(initialNameState);
  const [invoiceNumber, setInvoiceNumber] = useState(initialNameState);
  const [process, setProcess] = useState(initialNameArrayState);
  const [vendor, setVendor] = useState(initialNameState);
  const [subWareHouse, setSubWareHouse] = useState(initialNameState);
  const [dateTime, setDateTime] = useState("");
  const [processList, setProcessList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [subWareHouseList, setSubWareHouseList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);

  const [remark, setRemark] = useState("");
  const [isApplyToAll, setIsApplyToAll] = useState(true);
  const [isApplySubWareHouseToAll, setIsApplySubWareHouseToAll] = useState(
    true
  );
  const [isApplyOutwardToAll, setIsApplyIsApplyOutwardToAll] = useState(true);
  const [itemList, setItemList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [item, setItem] = useState(initialNameState);
  const [gstList, setGstList] = useState([]);
  const [unit, setUnit] = useState(initialNameState);
  const [qty, setQty] = useState(initialNameState);
  const [cost, setCost] = useState(initialNameState);
  const [discount, setDiscount] = useState(initialNameState);
  const [gst, setGst] = useState(initialNameState);
  const [vendorData, setVendorData] = useState(initialNameState);
  const [subWarehouseData, setSubWarehouseData] = useState(initialNameState);
  const [addDataDialog, setAddDataDialog] = useState(false);
  const [rawDataList, setRawDataList] = useState([]);
  const [convertedDataList, setConvertedDataList] = useState([]);
  const [tempProcessMultiplyList, setTempProcessMultiplyList] = useState([]);
  const [processMultiplyList, setProcessMultiplyList] = useState([]);
  const [itemStocks, setItemStocks] = useState([]);

  const multiSelectRef = useRef(null);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      getProcessList(data);
      getItemList(data);
      getUnitList(data);
      getSubWareHouseList(data);
      getVendorList(data);
      getClientList(data);
      getEquipmentList(data);
      getGSTList(data);
      getItemStocks(data);

      if (props?.location?.state) {
        let newState = props.location.state;
        if (newState.type === "Add" || !newState.type) {
          generateStockNo(data);
        } else if (newState.type === "View") {
          setReadOnly(true);
          setStockId(newState.stockId);
          getStockById(newState.stockId, data);
        } else if (newState.type === "Edit") {
          setStockId(newState.stockId);
          getStockById(newState.stockId, data);
        }
        if (newState.tabValue) {
          setTabValue(newState.tabValue);
        }
      }
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getProcessList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetProcessMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          setProcessList(
            response.Data.map((data) => ({
              ...data,
              label: data.ProcessName,
              value: data._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const getItemList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetItemMasterList, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              ...data,
              label: data.chapter_name,
              value: data.value,
            })
          );
          setItemList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getUnitList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetUnitMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data;

          setUnitList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getGSTList = async (UserId) => {
    let params = {
      ID: UserId,
      Type: "GST",
    };
    api
      .post(APIConstant.path.GetMasterListByType, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              label: data.name,
              value: data.value,
            })
          );

          setGstList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getVendorList = async (UserId) => {
    let params = {
      UserId: UserId,
      UserType: "Vendor",
    };
    api
      .post(APIConstant.path.GetNotVerifiedNetworkByUserType, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              label: data.Name,
              value: data._id,
            })
          );

          setVendorList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getClientList = async (UserId) => {
    let params = {
      UserId: UserId,
      UserType: "Client",
    };
    api
      .post(APIConstant.path.GetNotVerifiedNetworkByUserType, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              label: data.Name,
              value: data._id,
            })
          );

          setClientList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const addStock = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    let from = vendor;
    let fromList = vendorList;
    let to = subWareHouse;
    let toList = subWareHouseList;

    if (tabValue === 1) {
      from = subWareHouse;
      fromList = subWareHouseList;
      to = outwardTo;
      toList = outwardToList;
    }

    let OutwardType = "";
    if (tabValue === 1) {
      if (OutwardTabValue === 0) {
        OutwardType = "Sell";
      } else if (OutwardTabValue === 1) {
        OutwardType = "Machinery";
      } else {
        OutwardType = "Warehouse";
      }
    }
    let params = {
      UserId: userId,
      StockId: stockId,
      Type: tabValue === 0 ? "Inward" : "Outward",
      OutwardType,
      IdNo: inwardNumber.name,
      Process: processList.filter((data) => process.name.includes(data._id)),
      DateTime: dateTime,
      InvoiceNumber: invoiceNumber.name,
      Remark: remark,
      From: from.name,
      FromName:
        fromList.filter((data) => from.name === data.value)?.[0]?.label || "",
      To: to.name,
      ToName: toList.filter((data) => to.name === data.value)?.[0]?.label || "",
      RawData: rawDataList.map((data) => ({
        ...data,
        FromName:
          fromList.filter(
            (dataFrom) =>
              data[getRawDataLabel().Input1.value] === dataFrom.value
          )?.[0]?.label || "",
        ToName:
          toList.filter(
            (dataTo) => data[getRawDataLabel().Input2.value] === dataTo.value
          )?.[0]?.label || "",
      })),
      ConvertedData: convertedDataList.map((data) => ({
        ...data,
        FromName:
          fromList.filter(
            (dataFrom) =>
              data[getRawDataLabel().Input1.value] === dataFrom.value
          )?.[0]?.label || "",
        ToName:
          toList.filter(
            (dataTo) => data[getRawDataLabel().Input2.value] === dataTo.value
          )?.[0]?.label || "",
      })),
    };
    api
      .post(APIConstant.path.AddUpdateStock, params)
      .then((response) => {
        if (response.Success === 1) {
          cogoToast.success(response.Message);
          props.history.push("/admin//Stock");
        } else {
          cogoToast.error(response.Message);
        }
      })
      .catch((err) => {});
  };

  const getStockById = async (StockId, UserId) => {
    let params = {
      UserId: UserId || userId,
      StockId,
    };
    api
      .post(APIConstant.path.GetStockById, params)
      .then(async (response) => {
        if (response.Success === 1) {
          let data = response.Data;
          setInwardNumber({
            name: data.IdNo,
            error: false,
            errorText: "",
          });
          setInvoiceNumber({
            name: data.InvoiceNumber,
            error: false,
            errorText: "",
          });
          setDateTime(new Date(data.DateTime));
          setRemark(data.Remark);
          setOutwardTabValue(
            data.OutwardType === "Sell"
              ? 0
              : data.OutwardType === "Machinery"
              ? 1
              : 2
          );
          setProcess({
            name: data.Process.map((process) => process.ProcessId),
            error: false,
            errorText: "",
          });
          if (tabValue === 0) {
            setVendor({
              name: data.From,
              error: false,
              errorText: "",
            });
            setSubWareHouse({
              name: subWareHouseList.find(
                (warehouse) => warehouse.value === data.To
              )
                ? data.To
                : "",
              error: false,
              errorText: "",
            });
          } else {
            setSubWareHouse({
              name: subWareHouseList.find(
                (warehouse) => warehouse.value === data.From
              )
                ? data.From
                : "",
              error: false,
              errorText: "",
            });
            setOutwardTo({
              name: data.To,
              error: false,
              errorText: "",
            });
          }
          setRawDataList(data.RawData);
          setConvertedDataList(data.ConvertedData);
        }
      })
      .catch((err) => {});
  };

  const getSubWareHouseList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetSubWareHouseList, params)
      .then((response) => {
        if (response.Success === 1) {
          setSubWareHouseList(
            response.Data.map((subWareHouse) => ({
              label: subWareHouse.Name,
              value: subWareHouse._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const getEquipmentList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetEquipmentMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          setEquipmentList(
            response.Data.map((equipment) => ({
              label: equipment.EquipmentName,
              value: equipment._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const generateStockNo = async (UserId) => {
    let params = {
      UserId: UserId || userId,
      Type: tabValue === 0 ? "I" : "O",
    };
    api
      .post(APIConstant.path.GenerateUniqueStockNo, params)
      .then((response) => {
        if (response.Success === 1) {
          setInwardNumber({
            name: response.generatedID,
            error: false,
            errorText: "",
          });
        }
      })
      .catch((err) => {});
  };

  const checkUnitConversion = async (data) => {
    try {
      const response = await api.post(
        APIConstant.path.CheckUnitConversion,
        data
      );
      return response;
    } catch (err) {
      console.error("Error:", err);
      return null;
    }
  };

  const getItemStocks = async (UserId) => {
    let params = {
      UserId: UserId || userId,
      FilterData: {
        item: [],
        unit: [],
        subWareHouse: [],
        combined: "Individual",
      },
    };
    api
      .post(APIConstant.path.FilterAuditOrderList, params)
      .then((response) => {
        if (response.Success === 1) {
          if (response.Data?.length) {
            let stockObj = {};
            response.Data.forEach((item) => {
              let qtyObj = {};
              if (item.QuantityDetails?.length) {
                item.QuantityDetails.forEach((itemQuantity) => {
                  qtyObj[itemQuantity.SubWarehouseId] = itemQuantity.Quantity;
                });
                stockObj[item.ItemId] = qtyObj;
              }
            });
            setItemStocks(stockObj);
          }
        }
      })
      .catch((err) => {});
  };

  const validateForm = () => {
    let isValid = true;

    if (CommonConfig.isEmpty(invoiceNumber.name)) {
      setInvoiceNumber({
        ...invoiceNumber,
        error: true,
        errorText: "Please enter invoice number",
      });
      isValid = false;
    } else {
      setInvoiceNumber({
        ...invoiceNumber,
        error: false,
        errorText: "",
      });
    }

    if (tabValue === 0) {
      rawDataList.forEach((data) => {
        if (
          CommonConfig.isEmpty(data.Item) ||
          CommonConfig.isEmpty(data.ItemId)
        ) {
          isValid = false;
          cogoToast.error("Please select Item");
        } else if (CommonConfig.isEmpty(data.Vendor)) {
          isValid = false;
          cogoToast.error("Please select Vendor");
        } else if (
          CommonConfig.isEmpty(data.SubWarehouse) ||
          !subWareHouseList.find(
            (warehouse) => warehouse.value === data.SubWarehouse
          )
        ) {
          isValid = false;
          cogoToast.error("Please select Subwarehouse");
        }
      });
      convertedDataList.forEach((data) => {
        if (
          CommonConfig.isEmpty(data.Item) ||
          CommonConfig.isEmpty(data.ItemId)
        ) {
          isValid = false;
          cogoToast.error("Please select Item");
        } else if (CommonConfig.isEmpty(data.Vendor)) {
          isValid = false;
          cogoToast.error("Please select Vendor");
        } else if (
          CommonConfig.isEmpty(data.SubWarehouse) ||
          !subWareHouseList.find(
            (warehouse) => warehouse.value === data.SubWarehouse
          )
        ) {
          isValid = false;
          cogoToast.error("Please select Subwarehouse");
        }
      });
    }

    if (tabValue === 1) {
      rawDataList.forEach((data) => {
        if (
          CommonConfig.isEmpty(data.Item) ||
          CommonConfig.isEmpty(data.ItemId)
        ) {
          isValid = false;
          cogoToast.error("Please select Item");
        } else if (
          CommonConfig.isEmpty(data.SubWarehouse) ||
          !subWareHouseList.find(
            (warehouse) => warehouse.value === data.SubWarehouse
          )
        ) {
          isValid = false;
          cogoToast.error("Please select Subwarehouse");
        } else if (OutwardTabValue === 0 && CommonConfig.isEmpty(data.Client)) {
          isValid = false;
          cogoToast.error("Please select Client");
        } else if (
          OutwardTabValue === 1 &&
          CommonConfig.isEmpty(data.Equipment)
        ) {
          isValid = false;
          cogoToast.error("Please select Equipment");
        } else if (
          OutwardTabValue === 2 &&
          CommonConfig.isEmpty(data.ToSubWarehouse)
        ) {
          isValid = false;
          cogoToast.error("Please select SubWarehouse");
        }
      });
      convertedDataList.forEach((data) => {
        if (
          CommonConfig.isEmpty(data.Item) ||
          CommonConfig.isEmpty(data.ItemId)
        ) {
          isValid = false;
          cogoToast.error("Please select Item");
        } else if (
          CommonConfig.isEmpty(data.SubWarehouse) ||
          !subWareHouseList.find(
            (warehouse) => warehouse.value === data.SubWarehouse
          )
        ) {
          isValid = false;
          cogoToast.error("Please select Subwarehouse");
        } else if (OutwardTabValue === 0 && CommonConfig.isEmpty(data.Client)) {
          isValid = false;
          cogoToast.error("Please select Client");
        } else if (
          OutwardTabValue === 1 &&
          CommonConfig.isEmpty(data.Equipment)
        ) {
          isValid = false;
          cogoToast.error("Please select Equipment");
        } else if (
          OutwardTabValue === 2 &&
          CommonConfig.isEmpty(data.ToSubWarehouse)
        ) {
          isValid = false;
          cogoToast.error("Please select SubWarehouse");
        }
      });
    }

    return isValid;
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleOutwardTabChange = (e, value) => {
    setOutwardTabValue(value);
    setOutwardTo(initialNameState);
    setProcess(initialNameArrayState);
    setSubWareHouse(initialNameState);
    setRawDataList([]);
    setConvertedDataList([]);
  };

  const handleOpen = async (e, type) => {
    if (type === "AddRawData") {
      setAddDataDialog(true);
    } else if (type === "MultiplyProcess") {
      setMultiplyProcessDialog(true);
    }
  };

  const handleClose = (type) => {
    if (type === "addDataDialog") {
      setAddDataDialog(false);
      setItem(initialNameState);
      setUnit(initialNameState);
      setQty(initialNameState);
      setDiscount(initialNameState);
      setCost(initialNameState);
      setGst(initialNameState);
      setVendorData(initialNameState);
      setSubWarehouseData(initialNameState);
      setReadOnly(false);
    } else if (type === "multiplyProcessDialog") {
      setMultiplyProcessDialog(false);
      setTempProcessMultiplyList(_.cloneDeep(processMultiplyList));
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionBodyMultiplyBy = (rowData, props) => {
    return (
      <>
        <TextField
          autoFocus
          margin="dense"
          name="MultiplyBy"
          // label="Multiply By"
          type="text"
          value={rowData.MultiplyBy}
          onChange={(e) => handleMultiplyChange(e, props)}
          inputProps={{ maxLength: 10 }}
          fullWidth
        />
        X
      </>
    );
  };

  const handleMultiplyChange = (e, props) => {
    let tempProcessList = [...tempProcessMultiplyList];
    tempProcessList[props.rowIndex].MultiplyBy = e.target.value;
    setTempProcessMultiplyList(tempProcessList);
  };

  const handleChange = (e, type) => {
    if (type === "inwardNumber") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setInwardNumber({
          name: e.target.value,
          error: true,
          errorText: "Please enter number",
        });
      } else {
        setInwardNumber({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "invoiceNumber") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setInvoiceNumber({
          name: e.target.value,
          error: true,
          errorText: "Please enter invoice number",
        });
      } else {
        setInvoiceNumber({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "process") {
      let tempRawData = [...rawDataList];
      let tempConvertedData = [...convertedDataList];

      if (e.target.value.length <= 0) {
        tempRawData = tempRawData.filter((data) => !data.ProcessId);
        tempConvertedData = tempConvertedData.filter((data) => !data.ProcessId);
        setRawDataList(tempRawData);
        setConvertedDataList(tempConvertedData);
        setProcessMultiplyList([]);
        setTempProcessMultiplyList([]);
        setProcess({
          name: [],
          error: true,
          errorText: "Please select process name",
        });
      } else {
        e.target.value.forEach((id) => {
          let processData = processList.find((data) => data._id === id);
          let existingProcess = tempRawData.find(
            (data) => data.ProcessId === id
          );
          let tempProcessData = _.cloneDeep(processMultiplyList);
          if (!existingProcess) {
            tempProcessData.push({
              ProcessId: processData._id,
              ProcessName: processData.ProcessName,
              MultiplyBy: 1,
            });
          }
          setProcessMultiplyList(tempProcessData);
          setTempProcessMultiplyList(_.cloneDeep(tempProcessData));
          if (processData && processData.InputList?.length) {
            processData.InputList.forEach((input) => {
              if (!existingProcess) {
                let outwardToCheck =
                  tabValue === 1 && isApplyOutwardToAll && outwardTo.name;
                let subWarehouseCheck =
                  isApplySubWareHouseToAll && subWareHouse.name;
                tempRawData.push({
                  ProcessId: id,
                  Item: itemList.find((item) => item.value === input.Item)
                    ?.label,
                  ItemId: input.Item,
                  OriginalUnitId:
                    itemList.find((item) => item.value === input.Item)
                      ?.unitId || "",
                  Unit: unitList.find((item) => item.value === input.Unit)
                    ?.label,
                  UnitId: input.Unit,
                  OriginalQuantity: input.Quantity,
                  OriginalCost: 0,
                  OriginalDiscount: 0,
                  OriginalGST: 0,
                  OriginalPrice: 0,
                  Quantity: input.Quantity,
                  Cost: 0,
                  Discount: 0,
                  GST:
                    itemList.find((item) => item.value === input.Item)
                      ?.product_rate || 0,
                  Price: 0,
                  Vendor:
                    tabValue === 0 && vendor.name && isApplyToAll
                      ? vendor.name
                      : "",
                  Client:
                    outwardToCheck && OutwardTabValue === 0
                      ? outwardTo.name
                      : "",
                  Equipment:
                    outwardToCheck && OutwardTabValue === 1
                      ? outwardTo.name
                      : "",
                  SubWarehouse: subWarehouseCheck ? subWareHouse.name : "",
                  ToSubWarehouse:
                    outwardToCheck && OutwardTabValue === 2
                      ? outwardTo.name
                      : "",
                  MultiplyBy: 1,
                });
              }
            });
          }
          if (processData && processData.OutputList?.length) {
            processData.OutputList.forEach((output) => {
              let existingProcess = tempConvertedData.find(
                (data) => data.ProcessId === id
              );
              if (!existingProcess) {
                let outwardToCheck =
                  tabValue === 1 && isApplyOutwardToAll && outwardTo.name;
                let subWarehouseCheck =
                  isApplySubWareHouseToAll && subWareHouse.name;
                tempConvertedData.push({
                  ProcessId: id,
                  Item: itemList.find((item) => item.value === output.Item)
                    ?.label,
                  ItemId: output.Item,
                  OriginalUnitId:
                    itemList.find((item) => item.value === output.Item)
                      ?.unitId || "",
                  Unit: unitList.find((item) => item.value === output.Unit)
                    ?.label,
                  UnitId: output.Unit,
                  OriginalQuantity: output.Quantity,
                  OriginalCost: 0,
                  OriginalDiscount: 0,
                  OriginalGST: 0,
                  OriginalPrice: 0,
                  Quantity: output.Quantity,
                  Cost: 0,
                  Discount: 0,
                  GST:
                    itemList.find((item) => item.value === output.Item)
                      ?.product_rate || 0,
                  Price: 0,
                  Vendor:
                    tabValue === 0 && vendor.name && isApplyToAll
                      ? vendor.name
                      : "",
                  Client:
                    outwardToCheck && OutwardTabValue === 0
                      ? outwardTo.name
                      : "",
                  Equipment:
                    outwardToCheck && OutwardTabValue === 1
                      ? outwardTo.name
                      : "",
                  SubWarehouse: subWarehouseCheck ? subWareHouse.name : "",
                  ToSubWarehouse:
                    outwardToCheck && OutwardTabValue === 2
                      ? outwardTo.name
                      : "",
                  MultiplyBy: 1,
                });
              }
            });
          }
        });
        process.name.forEach((id) => {
          if (!e.target.value.includes(id)) {
            tempRawData = tempRawData.filter((data) => data.ProcessId !== id);
            tempConvertedData = tempConvertedData.filter(
              (data) => data.ProcessId !== id
            );
            setProcessMultiplyList((prevState) =>
              prevState.filter((list) => list.ProcessId !== id)
            );
            setTempProcessMultiplyList((prevState) =>
              prevState.filter((list) => list.ProcessId !== id)
            );
          }
        });
        setRawDataList(tempRawData);
        setConvertedDataList(tempConvertedData);
        setProcess({
          name: e.target.value,
          error: false,
          errorText: "",
        });
        // setTimeout(() => {
        //   document.getElementById("process-name").focus();
        //   multiSelectRef.current && multiSelectRef.current.show();
        // }, 0);
      }
    } else if (type === "remark") {
      setRemark(e.target.value);
    } else if (type === "vendor") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setVendor({
          name: "",
          error: true,
          errorText: `Please select vendor`,
        });
      } else {
        setVendor({
          name: e.value,
          error: false,
          errorText: "",
        });
        if (isApplyToAll) {
          let tempRawData = [...rawDataList];
          tempRawData = tempRawData.map((data) => {
            data.Vendor = e.value;
            return data;
          });
          setRawDataList(tempRawData);
          let tempConvertedData = [...convertedDataList];
          tempConvertedData = tempConvertedData.map((data) => {
            data.Vendor = e.value;

            return data;
          });
          setConvertedDataList(tempConvertedData);
        }
      }
    } else if (type === "outwardTo") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setOutwardTo({
          name: "",
          error: true,
          errorText: `Please select ${
            OutwardTabValue === 0
              ? "client"
              : OutwardTabValue === 1
              ? "equipment"
              : "sub warehouse"
          }`,
        });
      } else {
        setOutwardTo({
          name: e.value,
          error: false,
          errorText: "",
        });
        if (isApplyOutwardToAll) {
          let rawDataType = "Client";
          if (OutwardTabValue === 1) {
            rawDataType = "Equipment";
          } else if (OutwardTabValue === 2) {
            rawDataType = "ToSubWarehouse";
          }
          let tempRawData = [...rawDataList];
          tempRawData = tempRawData.map((data) => {
            data[rawDataType] = e.value;

            return data;
          });
          setRawDataList(tempRawData);
          let tempConvertedData = [...convertedDataList];
          tempConvertedData = tempConvertedData.map((data) => {
            data[rawDataType] = e.value;

            return data;
          });
          setConvertedDataList(tempConvertedData);
        }
      }
    } else if (type === "outwardToData") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setOutwardToData({
          name: "",
          error: true,
          errorText: `Please select ${
            OutwardTabValue === 0
              ? "client"
              : OutwardTabValue === 1
              ? "equipment"
              : "sub warehouse"
          }`,
        });
      } else {
        setOutwardToData({
          name: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "applyAll") {
      setIsApplyToAll((prevState) => !prevState);
      if (e.target.checked) {
        let tempRawData = [...rawDataList];
        tempRawData = tempRawData.map((data) => {
          if (vendor.name) {
            data.Vendor = vendor.name;
          }
          return data;
        });
        setRawDataList(tempRawData);
        let tempConvertedData = [...convertedDataList];
        tempConvertedData = tempConvertedData.map((data) => {
          if (vendor.name) {
            data.Vendor = vendor.name;
          }
          return data;
        });
        setConvertedDataList(tempConvertedData);
      }
    } else if (type === "applyOutwardToAll") {
      setIsApplyIsApplyOutwardToAll((prevState) => !prevState);
      if (e.target.checked) {
        let rawDataType = "Client";
        if (OutwardTabValue === 1) {
          rawDataType = "Equipment";
        } else if (OutwardTabValue === 2) {
          rawDataType = "ToSubWarehouse";
        }
        let tempRawData = [...rawDataList];
        tempRawData = tempRawData.map((data) => {
          if (outwardTo.name) {
            data[rawDataType] = outwardTo.name;
          }
          return data;
        });
        setRawDataList(tempRawData);
        let tempConvertedData = [...convertedDataList];
        tempConvertedData = tempConvertedData.map((data) => {
          if (outwardTo.name) {
            data[rawDataType] = outwardTo.name;
          }
          return data;
        });
        setConvertedDataList(tempConvertedData);
      }
    } else if (type === "listData") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setVendorData({
          name: "",
          error: true,
          errorText: `Please select vendor`,
        });
      } else {
        setVendorData({
          name: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "subwarehouse") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setSubWareHouse({
          name: "",
          error: true,
          errorText: `Please select Sub Warehouse`,
        });
      } else {
        setSubWareHouse({
          name: e.value,
          error: false,
          errorText: "",
        });
        if (isApplySubWareHouseToAll) {
          let tempRawData = [...rawDataList];
          tempRawData = tempRawData.map((data) => {
            data.SubWarehouse = e.value;

            return data;
          });
          setRawDataList(tempRawData);
          let tempConvertedData = [...convertedDataList];
          tempConvertedData = tempConvertedData.map((data) => {
            data.SubWarehouse = e.value;

            return data;
          });
          setConvertedDataList(tempConvertedData);
        }
      }
    } else if (type === "applySubWareHouseToAll") {
      setIsApplySubWareHouseToAll((prevState) => !prevState);
      if (e.target.checked) {
        let tempRawData = [...rawDataList];
        tempRawData = tempRawData.map((data) => {
          if (subWareHouse) {
            data.SubWarehouse = subWareHouse.name;
          }
          return data;
        });
        setRawDataList(tempRawData);
        let tempConvertedData = [...convertedDataList];
        tempConvertedData = tempConvertedData.map((data) => {
          if (subWareHouse.name) {
            data.SubWarehouse = subWareHouse.name;
          }
          return data;
        });
        setConvertedDataList(tempConvertedData);
      }
    } else if (type === "subWarehouseData") {
      if (CommonConfig.isObjectEmpty(e.value)) {
        setSubWarehouseData({
          name: "",
          error: true,
          errorText: `Please select Sub-Warehouse`,
        });
      } else {
        setSubWarehouseData({
          name: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "item") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setItem({
          name: "",
          error: true,
          errorText: "Please enter item",
        });
      } else {
        setItem({
          name: e.value,
          error: false,
          errorText: "",
        });
        let unitId =
          itemList.find((item) => item.value === e.value)?.unitId || "";
        setUnit({
          name: unitId,
          error: false,
          errorText: "",
        });
        setGst({
          name:
            itemList.find((item) => item.value === e.value)?.product_rate || 0,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "unit") {
      if (CommonConfig.isObjectEmpty(e?.value)) {
        setUnit({
          name: "",
          error: true,
          errorText: "Please enter unit",
        });
      } else {
        setUnit({
          name: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "qty") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setQty({
          name: e.target.value,
          error: true,
          errorText: "Please enter quantity",
        });
      } else if (/^\d*\.?\d*$/i.test(e.target.value)) {
        setQty({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "cost") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCost({
          name: e.target.value,
          error: true,
          errorText: "Please enter cost",
        });
      } else if (/^\d*\.?\d*$/.test(e.target.value)) {
        setCost({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "discount") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setDiscount({
          name: e.target.value,
          error: true,
          errorText: "Please enter discount",
        });
      } else if (/^\d*\.?\d*$/.test(e.target.value)) {
        setDiscount({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "gst") {
      if (CommonConfig.isEmpty(e?.value)) {
        setGst({
          name: e?.value,
          error: true,
          errorText: "Please enter gst",
        });
      } else {
        setGst({
          name: e.value,
          error: false,
          errorText: "",
        });
      }
    }
  };

  const addDataList = async () => {
    let priceObj = {
      Quantity: qty.name,
      Cost: cost.name,
      OriginalCost: cost.name,
      Discount: discount.name,
      GST: gst.name,
    };

    let tempRawData = [...rawDataList];

    let outwardToCheck = tabValue === 1 && outwardToData.name;

    let newItem = {
      ProcessId: "",
      Item:
        itemList.find((itemValue) => itemValue.value === item.name)?.label ||
        "",
      ItemId: item.name,
      OriginalUnitId:
        itemList.find((itemValue) => itemValue.value === item.name)?.unitId ||
        "",
      Unit:
        unitList.find((unitValue) => unitValue.value === unit.name)?.label ||
        "",
      UnitId: unit.name,
      ...priceObj,
      Price: calculatePrice(priceObj),
      Vendor: tabValue === 0 && vendorData.name,
      SubWarehouse: subWarehouseData.name,
      Client: outwardToCheck && OutwardTabValue === 0 ? outwardToData.name : "",
      Equipment:
        outwardToCheck && OutwardTabValue === 1 ? outwardToData.name : "",
      ToSubWarehouse:
        outwardToCheck && OutwardTabValue === 2 ? outwardToData.name : "",
      MultiplyBy: 1,
    };

    let response = await checkUnitConversion(newItem);
    if (response?.Success) {
      tempRawData.push(newItem);
      setRawDataList(tempRawData);

      handleClose("addDataDialog");
    } else {
      cogoToast.error(response?.Message);
    }
  };

  const handleMultiplyProcess = () => {
    setProcessMultiplyList(_.cloneDeep(tempProcessMultiplyList));
    let processMultiplyObj = {};
    tempProcessMultiplyList.forEach((process) => {
      processMultiplyObj[process.ProcessId] = process.MultiplyBy;
    });
    let tempRawData = [...rawDataList];
    tempRawData.forEach((data) => {
      if (data.ProcessId) {
        data.Quantity =
          processMultiplyObj[data.ProcessId] * data.OriginalQuantity;
        data.Price = calculatePrice(data);
      }
    });
    setRawDataList(tempRawData);
    let tempConvertedData = [...convertedDataList];
    tempConvertedData.forEach((data) => {
      if (data.ProcessId) {
        data.Quantity =
          processMultiplyObj[data.ProcessId] * data.OriginalQuantity;
        data.Price = calculatePrice(data);
      }
    });
    setConvertedDataList(tempConvertedData);
    setMultiplyProcessDialog(false);
  };

  const actionBodyData = (rowData, props, input, type) => {
    if (input === "Dropdown1") {
      let rowDataList = vendorList;
      if (tabValue === 1) {
        rowDataList = subWareHouseList;
      }
      return (
        <Autocomplete
          id={type}
          name={type}
          value={
            rowData[getRawDataLabel().Input1.value] && rowDataList.length
              ? rowDataList.filter(
                  (y) => y.value == rowData[getRawDataLabel().Input1.value]
                )[0]
              : ""
          }
          options={rowDataList}
          getOptionLabel={(option) => (option.label ? option.label : option)}
          disabled={readOnly}
          onChange={(e, value) =>
            handlerowDataChange(
              value?.value || "",
              props,
              getRawDataLabel().Input1.value,
              type
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={getRawDataLabel().Input1.label}
              disabled={readOnly}
            />
          )}
        />
      );
    }
    if (input === "Dropdown2") {
      let rowDataList = subWareHouseList;
      if (tabValue === 1) {
        switch (OutwardTabValue) {
          case 0:
            rowDataList = clientList;
            break;
          case 1:
            rowDataList = equipmentList;
            break;
        }
      }
      return (
        <Autocomplete
          id={type}
          name={type}
          disabled={readOnly}
          value={
            rowData[getRawDataLabel().Input2.value] && rowDataList.length
              ? rowDataList.filter(
                  (y) => y.value == rowData[getRawDataLabel().Input2.value]
                )[0]
              : ""
          }
          options={rowDataList}
          getOptionLabel={(option) => (option.label ? option.label : option)}
          onChange={(e, value) =>
            handlerowDataChange(
              value?.value || "",
              props,
              getRawDataLabel().Input2.value,
              type
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={getRawDataLabel().Input2.label}
              disabled={readOnly}
            />
          )}
        />
      );
    }

    if (input === "GST") {
      return (
        <Autocomplete
          id={type}
          name={type}
          value={
            (rowData.GST || rowData.GST === 0) && gstList.length
              ? gstList.filter((y) => y.value == rowData.GST)[0]
              : ""
          }
          options={gstList}
          getOptionLabel={(option) => (option.label ? option.label : option)}
          disabled={readOnly}
          onChange={(e, value) =>
            handlerowDataChange(value?.value || "", props, "GST", type)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="GST"
              disabled={readOnly}
            />
          )}
        />
      );
    }
    return (
      <TextField
        autoFocus
        margin="dense"
        name={type}
        type="text"
        value={rowData[input]}
        inputProps={{ maxLength: 10 }}
        onChange={(e) =>
          handlerowDataChange(e.target.value, props, input, type)
        }
        fullWidth
        disabled={readOnly}
      />
    );
  };

  const calculatePrice = (data) => {
    let { Quantity, OriginalCost, GST, Discount, Price } = data;
    let price = OriginalCost || 0;
    if (!Quantity) {
      Quantity = 1;
    }
    if (OriginalCost) {
      if (Discount) {
        price -= OriginalCost * (Discount / 100);
      }
      if (GST) {
        price += OriginalCost * (GST / 100);
      }
    }
    return price * Quantity;
  };

  const handlerowDataChange = (e, props, input, type) => {
    if (
      input === "Cost" ||
      input === "GST" ||
      input === "Discount" ||
      input === "Quantity"
    ) {
      if (!(/^\d*\.?\d*$/.test(e) || e === "")) {
        return;
      }
    }
    if (type === "Raw") {
      let tempRawData = [...rawDataList];
      tempRawData[props.rowIndex][input] = e;
      if (input === "Cost") {
        tempRawData[props.rowIndex].OriginalCost = e || 0;
      }
      if (input === "Cost" || input === "GST" || input === "Discount") {
        let price = calculatePrice(tempRawData[props.rowIndex]);
        tempRawData[props.rowIndex].Price = price;
      }
      setRawDataList(tempRawData);
    } else {
      let tempConvertedData = [...convertedDataList];
      tempConvertedData[props.rowIndex][input] = e;
      if (input === "Cost") {
        tempConvertedData[props.rowIndex].OriginalCost = e || 0;
      }
      if (input === "Cost" || input === "GST" || input === "Discount") {
        let price = calculatePrice(tempConvertedData[props.rowIndex]);
        tempConvertedData[props.rowIndex].Price = price;
      }
      setConvertedDataList(tempConvertedData);
    }
  };

  const getRawDataLabel = () => {
    let Input1 = {
      label: "Vendor's Name",
      value: "Vendor",
    };
    let Input2 = {
      label: "Sub-warehouse",
      value: "SubWarehouse",
    };
    if (tabValue === 1) {
      Input1 = {
        label: "From Sub-warehouse",
        value: "SubWarehouse",
      };
      switch (OutwardTabValue) {
        case 0:
          Input2 = {
            label: "Client's Name",
            value: "Client",
          };
          break;
        case 1:
          Input2 = {
            label: "Equipment's Name",
            value: "Equipment",
          };
          break;
        case 2:
          Input2 = {
            label: "To Sub-warehouse",
            value: "ToSubWarehouse",
          };
          break;
      }
    }

    return { Input1, Input2 };
  };

  const getAvailableItemQuantity = (itemId, subWareHouseId) => {
    if (itemId && itemStocks[itemId]) {
      if (subWareHouseId) {
        return itemStocks[itemId][subWareHouseId] || 0;
      } else {
        return Object.values(itemStocks[itemId]).reduce(
          (sum, qty) => sum + qty,
          0
        );
      }
    } else return 0;
  };

  let outwardToList =
    OutwardTabValue === 0
      ? clientList
      : OutwardTabValue === 1
      ? equipmentList
      : subWareHouseList;

  return (
    <div className="wayment-owner-dashboard e-weighment-dashoard">
      <div className="main-access-contianer e-main-access">
        <div
          className="p-d-flex p-ai-center"
          onClick={() => {
            props.history.push("/admin/Stock");
          }}
        >
          <i className="pi pi-chevron-left" />
          <h2>
            {stockId !== "" ? (readOnly ? "View " : "Edit ") : "Add "}
            {tabValue === 0 ? "Inward" : "Outward"}
          </h2>
        </div>

        <GridContainer className="p-w-100">
          <GridItem xs={12} sm={6} md={6}>
            <TextField
              style={{ marginTop: "22px" }}
              autoFocus
              margin="dense"
              name="InwardId"
              label={tabValue === 0 ? "Inward Id" : "Outward Id"}
              type="text"
              value={inwardNumber.name}
              onChange={(e) => handleChange(e, "inwardNumber")}
              error={inwardNumber.error}
              helperText={inwardNumber.errorText}
              disabled={true}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
            <Datetime
              value={dateTime ? moment(dateTime) : moment()}
              dateFormat="DD/MM/YYYY"
              timeFormat="HH:mm"
              onChange={(e) => setDateTime(e)}
              closeOnSelect={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginTop: "23px" }}
                  label={tabValue === 0 ? "Inward Date" : "Outward Date"}
                  fullWidth
                  disabled={readOnly}
                />
              )}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <label htmlFor="simple-select">Process Name</label>
                <div className="p-d-flex p-ai-end">
                  <MultiSelect
                    ref={multiSelectRef}
                    id="react-multiselect"
                    inputId="process-name"
                    value={process.name}
                    onChange={(e) => handleChange(e, "process")}
                    options={processList}
                    optionLabel="label"
                    placeholder="Process Name"
                    className="p-w-100 e-border-none"
                    disabled={readOnly}
                    closeOnSelect={false}
                  />

                  {/* <span className="error-msg" style={{ color: "red" }}>
                        {process.error ? process.errorText : null}
                      </span> */}
                  {!readOnly && (
                    <div
                      className="filter-btn cursor-pointer"
                      onClick={(e) => handleOpen(e, "MultiplyProcess")}
                    >
                      <i className="icon-add"></i>
                    </div>
                  )}
                </div>
              </FormControl>
            </div>
            <TextField
              margin="dense"
              name="InvoiceNumber"
              label="Invoice Number*"
              type="text"
              value={invoiceNumber.name}
              onChange={(e) => handleChange(e, "invoiceNumber")}
              error={invoiceNumber.error}
              helperText={invoiceNumber.errorText}
              disabled={readOnly}
              inputProps={{ maxLength: 50 }}
              fullWidth
              className="p-mt-3 p-pt-2"
            />
          </GridItem>
          {tabValue === 0 && (
            <GridItem xs={12} sm={6} md={6}>
              <div className="input-control select p-d-flex">
                <FormControl fullWidth>
                  <Autocomplete
                    id="vendor"
                    name="vendor"
                    value={
                      vendor.name && vendorList.length
                        ? vendorList.filter((y) => y.value == vendor.name)[0]
                        : ""
                    }
                    options={vendorList}
                    getOptionLabel={(option) =>
                      option.label ? option.label : option
                    }
                    disabled={readOnly}
                    onChange={(e, value) => handleChange(value, "vendor")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={"Vendor Name"}
                        error={vendor.error}
                        helperText={vendor.errorText}
                        label={"Vendor Name"}
                        disabled={readOnly}
                      />
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"ApplyAllVendor"}
                        checked={isApplyToAll}
                        onChange={(e) => handleChange(e, "applyAll")}
                        disabled={readOnly || !vendor.name}
                      />
                    }
                    label={`Apply this ${
                      tabValue === 0 ? "vendor" : "client"
                    }'s name to
                  all`}
                  />
                </FormControl>
              </div>
            </GridItem>
          )}
          {tabValue === 1 && (
            <GridItem xs={12} sm={12} md={12} className="p-mt-3 p-p-0">
              <Tabs
                value={OutwardTabValue}
                onChange={handleOutwardTabChange}
                aria-label="simple tabs example"
              >
                <Tab label="Sell" {...a11yProps(0)} />

                <Tab label="Machinery" {...a11yProps(1)} />
                <Tab label="Warehouse" {...a11yProps(2)} />
              </Tabs>
            </GridItem>
          )}

          <GridItem xs={12} sm={6} md={6}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <Autocomplete
                  id="subwarehouse"
                  name="subwarehouse"
                  value={
                    subWareHouse.name && subWareHouseList.length
                      ? subWareHouseList.filter(
                          (y) => y.value == subWareHouse.name
                        )[0]
                      : ""
                  }
                  options={subWareHouseList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  disabled={readOnly}
                  onChange={(e, value) => handleChange(value, "subwarehouse")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Sub Warehouse"
                      error={subWareHouse.error}
                      helperText={subWareHouse.errorText}
                      label={
                        tabValue === 0 ? "Sub-Warehouse" : "From Sub-warehouse"
                      }
                      disabled={readOnly}
                    />
                  )}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isApplySubWareHouseToAll}
                      onChange={(e) =>
                        handleChange(e, "applySubWareHouseToAll")
                      }
                      disabled={readOnly || !subWareHouse.name}
                    />
                  }
                  label=" Apply this Sub-Warehouse to all"
                />
              </FormControl>
            </div>
          </GridItem>

          {tabValue === 1 ? (
            <GridItem xs={12} sm={6} md={6}>
              <div className="input-control select p-d-flex">
                <FormControl fullWidth>
                  <Autocomplete
                    id="outwardTo"
                    name="outwardTo"
                    value={
                      outwardTo.name && outwardToList.length
                        ? outwardToList.filter(
                            (y) => y.value == outwardTo.name
                          )[0]
                        : ""
                    }
                    options={outwardToList}
                    getOptionLabel={(option) =>
                      option.label ? option.label : option
                    }
                    disabled={readOnly}
                    onChange={(e, value) => handleChange(value, "outwardTo")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select"
                        error={outwardTo.error}
                        helperText={outwardTo.errorText}
                        label={
                          OutwardTabValue === 0
                            ? "Party's Name"
                            : OutwardTabValue === 1
                            ? "Equipment’s Name"
                            : "To Sub-warehouse"
                        }
                        disabled={readOnly}
                      />
                    )}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isApplyOutwardToAll}
                        onChange={(e) => handleChange(e, "applyOutwardToAll")}
                        disabled={readOnly || !outwardTo.name}
                      />
                    }
                    label={` Apply this ${
                      OutwardTabValue === 0
                        ? "client"
                        : OutwardTabValue === 1
                        ? "equipment"
                        : "sub-warehouse"
                    } to all`}
                  />
                </FormControl>
              </div>
            </GridItem>
          ) : null}
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              margin="dense"
              name="Remark"
              label="Remark"
              type="text"
              disabled={readOnly}
              value={remark.name}
              onChange={(e) => handleChange(e, "remark")}
              error={remark.error}
              helperText={remark.errorText}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          </GridItem>

          <div>
            <div className="dialog-heading">
              <h2>Raw Data</h2>
              <hr />
              <DataTable
                className="main-table table-td"
                style={{ width: "calc(100% + 320px)", height: "auto" }}
                value={rawDataList}
                editMode="row"
                dataKey="id"
                responsiveLayout="scroll"
                emptyMessage="No records found"
                columnResizeMode="fit"
                scrollable={true}
              >
                <Column
                  field="Index"
                  header="Sr.No"
                  body={actionBodyTemplateSrNo}
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Item"
                  header="Items"
                  style={{ width: "20% !important" }}
                  body={(rowData, props) => {
                    if (rowData.ProcessId === "" && !readOnly) {
                      return (
                        <Autocomplete
                          id={"item"}
                          name={"item"}
                          value={
                            rowData.ItemId && itemList.length
                              ? itemList.filter(
                                  (y) => y.value == rowData.ItemId
                                )[0]
                              : ""
                          }
                          options={itemList}
                          getOptionLabel={(option) =>
                            option.label ? option.label : option
                          }
                          onChange={(e, value) => {
                            let tempRawData = [...rawDataList];
                            tempRawData[props.rowIndex].ItemId = value?.value;
                            if (value?.value) {
                              tempRawData[props.rowIndex].Item = itemList.find(
                                (item) => item.value === value?.value
                              )?.label;

                              tempRawData[props.rowIndex].OriginalUnitId =
                                itemList.find(
                                  (item) => item.value === value.value
                                )?.unitId || "";
                            }
                            setRawDataList(tempRawData);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Item"
                              disabled={readOnly}
                            />
                          )}
                        />
                      );
                    } else {
                      return rowData.Item;
                    }
                  }}
                ></Column>
                <Column
                  field="Unit"
                  header="Unit"
                  style={{ width: "20% !important" }}
                  body={(rowData, props) => {
                    if (rowData.ProcessId === "" && !readOnly) {
                      return (
                        <Autocomplete
                          id={"unit"}
                          name={"unit"}
                          value={
                            rowData.UnitId && unitList.length
                              ? unitList.filter(
                                  (y) => y.value == rowData.UnitId
                                )[0]
                              : ""
                          }
                          options={unitList}
                          getOptionLabel={(option) =>
                            option.label ? option.label : option
                          }
                          onChange={(e, value) => {
                            let tempRawData = [...rawDataList];
                            tempRawData[props.rowIndex].UnitId = value?.value;
                            if (value?.value) {
                              tempRawData[props.rowIndex].Unit = unitList.find(
                                (unit) => unit.value === value?.value
                              )?.label;
                            }
                            setRawDataList(tempRawData);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Item"
                              disabled={readOnly}
                            />
                          )}
                        />
                      );
                    } else {
                      return rowData.Unit;
                    }
                  }}
                ></Column>
                <Column
                  field="Quantity"
                  header="Qty"
                  style={{ width: "20% !important" }}
                  body={(rowData, props) => {
                    if (rowData.ProcessId === "" && !readOnly) {
                      return actionBodyData(rowData, props, "Quantity", "Raw");
                    } else {
                      return rowData.Quantity;
                    }
                  }}
                ></Column>
                <Column
                  field="AvailableQuantity"
                  header="Available Qty"
                  style={{ width: "20% !important" }}
                  body={(rowData) => {
                    return getAvailableItemQuantity(
                      rowData.ItemId,
                      rowData.SubWarehouse
                    );
                  }}
                ></Column>
                {(userPageAccess.includes("Outward Cost") && tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="Cost"
                    header="Cost"
                    style={{ width: "20% !important" }}
                    body={(rowData, props) =>
                      actionBodyData(rowData, props, "Cost", "Raw")
                    }
                  ></Column>
                ) : null}
                {(userPageAccess.includes("Outward Discount") &&
                  tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="Discount"
                    header="Discount"
                    style={{ width: "20% !important" }}
                    body={(rowData, props) =>
                      actionBodyData(rowData, props, "Discount", "Raw")
                    }
                  ></Column>
                ) : null}
                {(userPageAccess.includes("Outward GST") && tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="GST"
                    header="G.S.T"
                    style={{ width: "20% !important" }}
                    body={(rowData, props) =>
                      actionBodyData(rowData, props, "GST", "Raw")
                    }
                  ></Column>
                ) : null}
                {(userPageAccess.includes("Outward Price") && tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="Price"
                    header="Price"
                    style={{ width: "20% !important" }}
                  ></Column>
                ) : null}
                <Column
                  field={getRawDataLabel().Input1.value}
                  header={getRawDataLabel().Input1.label}
                  style={{ width: "20% !important" }}
                  body={(rowData, props) =>
                    actionBodyData(rowData, props, "Dropdown1", "Raw")
                  }
                ></Column>

                <Column
                  field={getRawDataLabel().Input2.value}
                  header={getRawDataLabel().Input2.label}
                  style={{ width: "20% !important" }}
                  body={(rowData, props) =>
                    actionBodyData(rowData, props, "Dropdown2", "Raw")
                  }
                ></Column>
                <Column
                  header="Action"
                  style={{ width: "20% !important" }}
                  body={(rowData, props) => (
                    <div>
                      {rowData.ProcessId === "" && (
                        <i
                          class="icon-delete"
                          onClick={() => {
                            let tempData = [...rawDataList];
                            tempData = tempData.filter(
                              (data, index) => index !== props.rowIndex
                            );
                            setRawDataList(tempData);
                          }}
                        ></i>
                      )}
                    </div>
                  )}
                ></Column>
              </DataTable>
              <div className="p-d-flex p-ai-center">
                <p className="e-add-row">add raw data</p>
                {!readOnly && (
                  <div
                    className="filter-btn cursor-pointer"
                    onClick={(e) => handleOpen(e, "AddRawData")}
                  >
                    <i className="icon-add"></i>
                  </div>
                )}
              </div>
            </div>

            <div className="dialog-heading">
              <h2>Converted Data</h2>
              <hr />
              <DataTable
                className="main-table table-td data-table"
                style={{ width: "calc(100% + 320px)", height: "auto" }}
                value={convertedDataList}
                editMode="row"
                dataKey="id"
                responsiveLayout="scroll"
                emptyMessage="No records found"
                columnResizeMode="fit"
                scrollable={true}
              >
                <Column
                  field="Index"
                  header="Sr.No"
                  body={actionBodyTemplateSrNo}
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Item"
                  header="Items"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Unit"
                  header="Unit"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="Quantity"
                  header="Qty"
                  style={{ width: "20% !important" }}
                ></Column>
                <Column
                  field="AvailableQuantity"
                  header="Available Qty"
                  style={{ width: "20% !important" }}
                  body={(rowData) => {
                    return getAvailableItemQuantity(
                      rowData.ItemId,
                      rowData.SubWarehouse
                    );
                  }}
                ></Column>
                {(userPageAccess.includes("Outward Cost") && tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="Cost"
                    header="Cost"
                    style={{ width: "20% !important" }}
                    body={(rowData, props) =>
                      actionBodyData(rowData, props, "Cost", "Converted")
                    }
                  ></Column>
                ) : null}
                {(userPageAccess.includes("Outward Discount") &&
                  tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="Discount"
                    header="Discount"
                    style={{ width: "20% !important" }}
                    body={(rowData, props) =>
                      actionBodyData(rowData, props, "Discount", "Converted")
                    }
                  ></Column>
                ) : null}
                {(userPageAccess.includes("Outward GST") && tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="GST"
                    header="G.S.T"
                    style={{ width: "20% !important" }}
                    body={(rowData, props) =>
                      actionBodyData(rowData, props, "GST", "Converted")
                    }
                  ></Column>
                ) : null}
                {(userPageAccess.includes("Outward Price") && tabValue === 1) ||
                tabValue === 0 ? (
                  <Column
                    field="Price"
                    header="Price"
                    style={{ width: "20% !important" }}
                  ></Column>
                ) : null}

                <Column
                  field={getRawDataLabel().Input1.value}
                  header={getRawDataLabel().Input1.label}
                  style={{ width: "20% !important" }}
                  body={(rowData, props) =>
                    actionBodyData(rowData, props, "Dropdown1", "Converted")
                  }
                ></Column>

                <Column
                  field={getRawDataLabel().Input2.value}
                  header={getRawDataLabel().Input2.label}
                  style={{ width: "20% !important" }}
                  body={(rowData, props) =>
                    actionBodyData(rowData, props, "Dropdown2", "Converted")
                  }
                ></Column>
              </DataTable>
            </div>
          </div>
        </GridContainer>

        {!readOnly && (
          <>
            <Button
              className="primary-btn1"
              onClick={(e) => props.history.push("/admin/Stock")}
            >
              Cancel
            </Button>
            <Button className="primary-btn" onClick={addStock} type="submit">
              Submit
            </Button>
          </>
        )}

        <Dialog
          open={multiplyProcessDialog}
          onClose={() => handleClose("multiplyProcessDialog")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Process Multiplication</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("multiplyProcessDialog")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <GridContainer>
              <DataTable
                className="main-table table-td"
                value={tempProcessMultiplyList}
                editMode="row"
                dataKey="id"
                responsiveLayout="scroll"
                emptyMessage="No records found"
                columnResizeMode="fit"
                scrollable={true}
              >
                <Column
                  field="Index"
                  header="Sr.No"
                  body={actionBodyTemplateSrNo}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="ProcessName"
                  header="Process Name"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="MultiplyBy"
                  header="Multiply"
                  style={{ width: "20%" }}
                  body={actionBodyMultiplyBy}
                ></Column>
              </DataTable>
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("multiplyProcessDialog")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => handleMultiplyProcess(e)}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={addDataDialog}
          onClose={() => handleClose("addDataDialog")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Add Raw Data</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("addDataDialog")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <div className="input-control select p-d-flex">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="item"
                      name="item"
                      value={
                        item.name && itemList.length
                          ? itemList.filter((y) => y.value == item.name)[0]
                          : ""
                      }
                      options={itemList}
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      onChange={(e, value) => handleChange(value, "item")}
                      renderInput={(params) => (
                        <TextField
                          autoFocus
                          {...params}
                          variant="standard"
                          placeholder="Item Name"
                          error={item.error}
                          helperText={item.errorText}
                          label="Item Name"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <div className="input-control select p-d-flex">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="unit"
                      name="unit"
                      value={
                        unit.name && unitList.length
                          ? unitList.filter((y) => y.value == unit.name)[0]
                          : ""
                      }
                      options={unitList}
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      onChange={(e, value) => handleChange(value, "unit")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Unit Name"
                          error={unit.error}
                          helperText={unit.errorText}
                          label="Unit Name"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </GridItem>
              <GridItem md={12}>
                <TextField
                  margin="dense"
                  name="Qty"
                  label="Quantity*"
                  type="text"
                  inputProps={{ maxLength: 10 }}
                  value={qty.name}
                  onChange={(e) => handleChange(e, "qty")}
                  error={qty.error}
                  helperText={qty.errorText}
                  fullWidth
                />
              </GridItem>
              <GridItem md={12}>
                <TextField
                  margin="dense"
                  name="Cost"
                  label="Cost*"
                  type="text"
                  value={cost.name}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => handleChange(e, "cost")}
                  error={cost.error}
                  helperText={cost.errorText}
                  fullWidth
                />
              </GridItem>
              <GridItem md={12}>
                <TextField
                  margin="dense"
                  name="Discount"
                  label="Discount*"
                  type="text"
                  value={discount.name}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => handleChange(e, "discount")}
                  error={discount.error}
                  helperText={discount.errorText}
                  fullWidth
                />
              </GridItem>
              <GridItem md={12}>
                <Autocomplete
                  id="gst"
                  name="gst"
                  value={
                    (gst.name || gst.name === 0) && gstList.length
                      ? gstList.filter((y) => y.value == gst.name)[0]
                      : ""
                  }
                  options={gstList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  disabled={readOnly}
                  onChange={(e, value) => handleChange(value, "gst")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="GST"
                      disabled={readOnly}
                    />
                  )}
                />
              </GridItem>

              {tabValue === 0 && (
                <GridItem xs={12} sm={6} md={6}>
                  <div className="input-control select p-d-flex">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="vendor"
                        name="vendor"
                        value={
                          vendorData.name && vendorList.length
                            ? vendorList.filter(
                                (y) => y.value == vendorData.name
                              )[0]
                            : ""
                        }
                        options={vendorList}
                        getOptionLabel={(option) =>
                          option.label ? option.label : option
                        }
                        onChange={(e, value) => handleChange(value, "listData")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder={
                              tabValue === 0 ? "Vendor Name" : "Client Name"
                            }
                            error={vendorData.error}
                            helperText={vendorData.errorText}
                            label={
                              tabValue === 0 ? "Vendor Name" : "Client Name"
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </GridItem>
              )}

              <GridItem xs={12} sm={6} md={6}>
                <div className="input-control select p-d-flex">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="vendor"
                      name="vendor"
                      value={
                        subWarehouseData.name && subWareHouseList.length
                          ? subWareHouseList.filter(
                              (y) => y.value == subWarehouseData.name
                            )[0]
                          : ""
                      }
                      options={subWareHouseList}
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      onChange={(e, value) =>
                        handleChange(value, "subWarehouseData")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Sub Warehouse"
                          error={subWarehouseData.error}
                          helperText={subWarehouseData.errorText}
                          label="Sub-Warehouse"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </GridItem>
              {tabValue === 1 && (
                <GridItem xs={12} sm={6} md={6}>
                  <div className="input-control select p-d-flex">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="outwardTo"
                        name="outwardTo"
                        value={
                          outwardToData.name && outwardToList.length
                            ? outwardToList.filter(
                                (y) => y.value == outwardToData.name
                              )[0]
                            : ""
                        }
                        options={outwardToList}
                        getOptionLabel={(option) =>
                          option.label ? option.label : option
                        }
                        onChange={(e, value) =>
                          handleChange(value, "outwardToData")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Select"
                            error={outwardTo.error}
                            helperText={outwardTo.errorText}
                            label={
                              OutwardTabValue === 0
                                ? "Party's Name"
                                : OutwardTabValue === 1
                                ? "Equipment’s Name"
                                : "To Sub-warehouse"
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </GridItem>
              )}
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("addDataDialog")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => addDataList(e)}
              type="submit"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AddStock;
