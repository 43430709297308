import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  FormControlLabel,
  FormControl,
  DialogActions,
  Switch,
  Typography,
  Box,
  IconButton,
  DialogContent,
  RadioGroup,
  Radio,
  Popover,
} from "@material-ui/core";
import { Button } from "primereact/button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CommonConfig } from "CommonConfig";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AccessTime, CalendarToday, Repeat } from "@material-ui/icons";
import APIConstant from "utils/constant";
import api from "utils/api";
import cogoToast from "cogo-toast";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import jsPDF from "jspdf";
import "jspdf-autotable";

const initialNameState = {
  name: "",
  error: false,
  errorText: "",
};

const AuditOrder = (props) => {
  const [search, setSearch] = useState("");
  const [auditOrderList, setAuditOrderList] = useState([]);
  const [userId, setUserId] = useState([]);
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [auditorderId, setAuditOrderId] = useState("");
  const [generatedAuditNo, setGeneratedAuditNo] = useState("");
  const [auditNo, setAuditNo] = useState(initialNameState);
  const [orderNo, setOrderNo] = useState(initialNameState);
  const [itemList, setItemList] = useState([]);
  const [godownList, setGodownList] = useState([]);
  const [filterData, setFilterData] = useState({
    item: [],
    unit: [],
    subWareHouse: [],
    combined: "Combined",
  });
  const [filterDialogData, setFilterDialogData] = useState({
    item: [],
    unit: [],
    subWareHouse: [],
    combined: "Combined",
  });
  const [unitList, setUnitList] = useState([]);
  const [auditData, setAuditData] = useState({
    Items: [],
  });
  const [addAuditorderDialog, setAddAuditorderDialog] = useState(false);
  const [auditSearchDialog, setAuditSearchDialog] = useState(false);
  const [auditFilterDialog, setAuditFilterDialog] = useState(false);
  const [auditPDFDialog, setAuditPDFDialog] = useState(false);
  const [auditDataDialog, setAuditDataDialog] = useState(false);
  const [orderDialog, setOrderDialog] = useState(false);
  const [orderScreenDialog, setOrderScreenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [compareWith, setCompareWith] = useState("IdealLimit");
  const [compareData, setCompareData] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    CommonConfig.dataTableConfig.rowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonConfig.dataTableConfig.rowsPerPage
  );
  const [editItem, setEditItem] = useState({});
  const [editItemDialog, setEditItemDialog] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      getAuditOrderList(data);
      getItemList(data);
      getUnitList(data);
      getSubWareHouseList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getAuditOrderList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetAuditOrderList, params)
      .then((response) => {
        if (response.Success === 1) {
          setAuditOrderList(
            response.Data.map((res) => ({
              ...res,
              Quantity: res.QuantityDetails.reduce(
                (accumulator, current) => accumulator + current.Quantity,
                0
              ),
              Godown: res.QuantityDetails.length,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const getItemList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetItemMasterList, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              label: data.chapter_name,
              value: data.value,
            })
          );
          setItemList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getUnitList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetUnitMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data;
          setUnitList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getSubWareHouseList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetSubWareHouseList, params)
      .then((response) => {
        if (response.Success === 1) {
          setGodownList(
            response.Data.map((subWareHouse) => ({
              label: subWareHouse.Name,
              value: subWareHouse._id,
            }))
          );
        }
      })
      .catch((err) => {});
  };

  const generateAuditNumber = async () => {
    let params = {
      UserId: userId,
    };
    api
      .post(APIConstant.path.GenerateAuditNumber, params)
      .then((response) => {
        if (response.Success === 1) {
          setGeneratedAuditNo(response.generatedID);
        }
      })
      .catch((err) => {});
  };

  const generateOrderNumber = async () => {
    let params = {
      UserId: userId,
    };
    api
      .post(APIConstant.path.GenerateOrderNumber, params)
      .then((response) => {
        if (response.Success === 1) {
          setOrderNo({
            name: response.generatedID,
            error: false,
            errorText: "",
          });
        }
      })
      .catch((err) => {});
  };

  const addUpdateAuditItems = async (auditNumber, auditList) => {
    let params = {
      UserId: userId,
      AuditNumber: auditNumber || generatedAuditNo,
      Data: auditList || auditOrderList,
      Remark: auditData.Remark || "",
    };
    api
      .post(APIConstant.path.AddUpdateAuditItems, params)
      .then((response) => {
        if (response.Success === 1) {
          cogoToast.success(response.Message);

          handleClose("AuditPDF");
          if (auditList) {
            getAuditOrderList(userId);
            handleClose("AuditOrder");
            handleClose("AuditData");
          } else {
            downloadAuditPdf(auditOrderList);
          }
        }
      })
      .catch((err) => {});
  };

  const getAuditItems = async () => {
    let params = {
      UserId: userId,
      AuditNumber: auditNo.name,
    };
    api
      .post(APIConstant.path.GetAuditItems, params)
      .then((response) => {
        if (response.Success === 1) {
          if (!response.Data.UpdatedOn) {
            setAuditData(response.Data);
            handleOpen("", "AuditData");
          } else {
            cogoToast.success("Audit already generated");
          }
        } else {
          cogoToast.error(response.Message);
        }
      })
      .catch((err) => {});
  };

  const handleFilter = async () => {
    let params = {
      UserId: userId,
      FilterData: filterDialogData,
    };
    api
      .post(APIConstant.path.FilterAuditOrderList, params)
      .then((response) => {
        if (response.Success === 1) {
          if (filterDialogData.combined === "Combined") {
            setAuditOrderList(
              response.Data.map((res) => ({
                ...res,
                Quantity: res.QuantityDetails.reduce(
                  (accumulator, current) => accumulator + current.Quantity,
                  0
                ),
                Godown: res.QuantityDetails.length,
              }))
            );
          } else {
            let tempList = [];
            response.Data.forEach((data) => {
              if (data.QuantityDetails?.length) {
                data.QuantityDetails.forEach((quantityDetails) => {
                  tempList.push({
                    _id: data._id,
                    ItemId: data.ItemId,
                    Item: data.Item,
                    UnitId: data.UnitId,
                    Unit: data.Unit,
                    Quantity: quantityDetails.Quantity,
                    Godown: quantityDetails.SubWarehouseName,
                    GodownId: quantityDetails.SubWarehouseId,
                  });
                });
              }
            });
            setAuditOrderList(tempList);
          }
          setFilterData({...filterDialogData});

          handleClose("AuditFilter");
        }
      })
      .catch((err) => {});
  };

  const compareOrderData = async () => {
    let params = {
      UserId: userId,
      Data: auditOrderList,
      CompareWith: compareWith,
    };
    api
      .post(APIConstant.path.CompareOrderData, params)
      .then((response) => {
        if (response.Success === 1) {
          setCompareData(response.Data);
          setOrderScreenDialog(true);
        }
      })
      .catch((err) => {});
  };

  const executeOrder = async () => {
    let params = {
      UserId: userId,
      OrderNumber: orderNo.name,
      Data: compareData,
    };
    api
      .post(APIConstant.path.AddUpdateOrderItems, params)
      .then((response) => {
        if (response.Success === 1) {
          cogoToast.success(response.Message);
          handleClose("OrderScreen");
          handleClose("Order");

          downloadOrderPdf(response.Data);
        }
      })
      .catch((err) => {});
  };

  const downloadOrderPdf = (orderDoc) => {
    const { OrderNumber, Items } = orderDoc;

    // Initialize jsPDF
    const doc = new jsPDF();

    // Add a title to the PDF
    doc.setFontSize(18);
    doc.text("Order Details", 20, 20);

    // Add Order Number
    doc.setFontSize(12);
    doc.text(`Order Number: ${OrderNumber}`, 20, 30);

    // Set up table columns
    const columns = [
      { header: "Item", dataKey: "Item" },
      { header: "Unit", dataKey: "Unit" },
      { header: "Godown", dataKey: "Godown" },
      { header: "Order Quantity", dataKey: "OrderQuantity" },
    ];

    // Map items to include only relevant fields
    const rows = Items.map((item) => ({
      Item: item.Item, // Handle case where Item might be null
      Unit: item.Unit,
      Godown: item.Godown,
      OrderQuantity: item.OrderQuantity,
    }));

    // Generate the table in the PDF
    doc.autoTable({
      head: [columns.map((col) => col.header)],
      body: rows.map((row) => columns.map((col) => row[col.dataKey])),
      startY: 40, // Position the table below the title
    });

    // Save the PDF
    doc.save(`order_${OrderNumber}.pdf`);
  };

  const downloadAuditPdf = (auditData) => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Add a title to the PDF
    doc.setFontSize(18);
    doc.text("Audit Details", 20, 20);

    // Add Order Number
    doc.setFontSize(12);
    doc.text(`Audit Number: ${generatedAuditNo}`, 20, 30);

    // Set up table columns
    const columns = [
      { header: "Item", dataKey: "Item" },
      { header: "Actual", dataKey: "ActualStock" },
      { header: "Unit", dataKey: "Unit" },
      { header: "Godown", dataKey: "Godown" },
    ];

    // Map items to include only relevant fields
    const rows = auditData.map((item) => ({
      Item: item.Item, // Handle case where Item might be null
      ActualStock: item.ActualStock,
      Unit: item.Unit,
      Godown: item.Godown,
    }));

    // Generate the table in the PDF
    doc.autoTable({
      head: [columns.map((col) => col.header)],
      body: rows.map((row) => columns.map((col) => row[col.dataKey])),
      startY: 40, // Position the table below the title
    });

    // Save the PDF
    doc.save(`audit_${generatedAuditNo}.pdf`);
  };

  const handleEditItem = () => {
    let params = {
      UserId: userId,
      Data: editItem,
    };
    api
      .post(APIConstant.path.UpdateStockItems, params)
      .then((response) => {
        if (response.Success === 1) {
          cogoToast.success(response.Message);
          handleClose("EditMasterType");
          handleFilter(userId);
        }
      })
      .catch((err) => {});
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const handleOpen = async (e, type) => {
    if (type === "AuditSearch") {
      setAuditSearchDialog(true);
    } else if (type === "ViewMasterType") {
      setAuditOrderId(e._id);
      //   await getReminderById(e._id);
      setReadOnly(true);
      setAddAuditorderDialog(true);
    } else if (type === "EditMasterType") {
      if (filterData.combined === "Individual") {
        setEditItem({
          _id: e._id,
          Item: e.ItemId,
          Unit: e.UnitId,
          Quantity: e.Quantity,
          Godown: e.GodownId,
        });
        setEditItemDialog(true);
      }
    } else if (type === "DeleteMasterType") {
      setAuditOrderId(e._id);
      setDeleteDialog(true);
    } else if (type === "AuditFilter") {
      setAuditFilterDialog(true);
    } else if (type === "Order") {
      if (filterData.combined === "Individual") {
        await generateOrderNumber();
        setOrderDialog(true);
      }
    } else if (type === "AuditPDF") {
      if (filterData.combined === "Individual") {
        await generateAuditNumber();
        setAuditPDFDialog(true);
      }
    } else if (type === "AuditData") {
      setAuditDataDialog(true);
    } else if (type === "OrderScreen") {
      setOrderScreenDialog(true);
    }
  };

  const handleClose = (type) => {
    if (type === "AuditOrder") {
      setAuditSearchDialog(false);
      setAuditNo("");
    } else if (type === "AuditFilter") {
      setAuditFilterDialog(false);
    } else if (type === "Order") {
      setOrderDialog(false);
    } else if (type == "AuditPDF") {
      setAuditPDFDialog(false);
    } else if (type == "AuditData") {
      setAuditDataDialog(false);
    } else if (type === "OrderScreen") {
      setOrderScreenDialog(false);
    } else if (type === "Order") {
      setOrderDialog(false);
      setOrderNo("");
    } else if (type === "EditMasterType") {
      setEditItemDialog(false);
      setEditItem({});
    }
  };

  const handleChange = (e, type) => {
    if (type === "auditNo") {
      {
        if (CommonConfig.isObjectEmpty(e.target.value)) {
          setAuditNo({
            name: "",
            error: true,
            errorText: `Please enter Audit No`,
          });
        } else {
          setAuditNo({
            name: e.target.value,
            error: false,
            errorText: "",
          });
        }
      }
    } else if (type === "item") {
      setFilterDialogData((prevState) => ({
        ...prevState,
        item: e?.value,
      }));
    } else if (type === "unit") {
      setFilterDialogData((prevState) => ({
        ...prevState,
        unit: e?.value,
      }));
    } else if (type === "subWarehouse") {
      setFilterDialogData((prevState) => ({
        ...prevState,
        subWareHouse: e.target.value,
      }));
    } else if (type === "combined") {
      setFilterDialogData((prevState) => ({
        ...prevState,
        combined: e,
      }));
    } else if (type === "remark") {
      setAuditData((prevState) => ({
        ...prevState,
        Remark: e.target?.value,
      }));
    } else if (type === "compareOrderWith") {
      setCompareWith(e);
    } else if (type === "qty") {
      if (/^\d*\.?\d*$/.test(e.target?.value) || e.target?.value === "") {
        setEditItem((prevState) => ({
          ...prevState,
          Quantity: e.target?.value,
        }));
      }
    }
  };

  const actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {userPageAccess.includes("View Audit Order") ? (
          <div onClick={(e) => handleOpen(rowData, "ViewMasterType")}>
            <i class="icon-view"></i>
          </div>
        ) : null}

        {/* userPageAccess.includes("Edit Audit Order") &&  */}
        {filterData.combined === "Individual" ? (
          <div onClick={(e) => handleOpen(rowData, "EditMasterType")}>
            <i class="icon-edit"></i>
          </div>
        ) : null}

        {userPageAccess.includes("Delete Audit Order") ? (
          <div onClick={(e) => handleOpen(rowData, "DeleteMasterType")}>
            <i class="icon-delete"></i>
          </div>
        ) : null}
      </div>
    );
  };

  const actionBodyTemplateAuditData = (rowData, props, type) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          autoFocus
          margin="dense"
          name={type}
          type={"text"}
          value={rowData[type]}
          onChange={(e) => {
            if (type === "ActualStock") {
              if (/^\d*\.?\d*$/.test(e.target.value) || e.target.value === "") {
                let tempData = { ...auditData };
                tempData.Items[props.rowIndex][type] = e.target.value;
                setAuditData(tempData);
              }
            } else {
              let tempData = { ...auditData };
              tempData.Items[props.rowIndex][type] = e.target.value;
              setAuditData(tempData);
            }
          }}
          fullWidth
        />
        {type === "ActualStock" &&
          !CommonConfig.isObjectEmpty(
            auditData.Items[props.rowIndex].ActualStock
          ) && (
            <span>
              (
              {auditData.Items[props.rowIndex].ActualStock -
                auditData.Items[props.rowIndex].CurrentStock}
              )
            </span>
          )}
      </div>
    );
  };

  const actionBodyTemplateOrderData = (rowData, props) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          autoFocus
          margin="dense"
          name="Order Quantity"
          type="text"
          value={rowData.OrderQuantity}
          onChange={(e) => {
            if (/^\d*$/i.test(e.target.value) || e.target.value === "") {
              let tempData = [...compareData];
              tempData[props.rowIndex].OrderQuantity = e.target.value;
              setCompareData(tempData);
            }
          }}
          inputProps={{ maxLength: 10 }}
          fullWidth
        />
      </div>
    );
  };
  return (
    <div className="wayment-owner-dashboard e-weighment-dashoard">
      <div className="heading-area p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-ai-center">
          <i className="pi pi-book"></i>
          <h2>Audit & Order</h2>
        </div>
        <div className="p-d-flex p-ai-center wb-filter-btn">
          <div className="searchbar-area p-as-center">
            <InputText
              type="search"
              onInput={(e) => setSearch(e.target.value)}
              placeholder="Global Search"
              size="30"
              style={{ marginRight: 10 }}
            />
            <i className="icon-search"></i>
          </div>

          <div className="icon-buttons">
            <button
              className="p-button p-component wb-search-btn p-ml-2 p-button-icon-only"
              onClick={(e) => handleOpen(e, "AuditFilter")}
            >
              <i className="pi pi-filter p-mr-0"></i>
            </button>
            <button
              className="p-button p-component wb-search-btn p-ml-2 p-button-icon-only"
              onClick={(e) => handleOpen(e, "AuditPDF")}
            >
              <i className="pi pi-file-pdf p-mr-0"></i>
            </button>
            <button
              className="p-button p-component wb-search-btn p-ml-2 p-button-icon-only"
              onClick={(e) => handleOpen(e, "AuditSearch")}
            >
              <i className="pi pi-search-plus p-mr-0"></i>
            </button>
            <button
              className="p-button p-component wb-search-btn p-ml-2 p-button-icon-only"
              onClick={(e) => handleOpen(e, "Order")}
            >
              <i className="pi pi-cart-plus p-mr-0"></i>
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="main-access-card-master">
        <DataTable
          className="main-table table-td"
          value={auditOrderList}
          editMode="row"
          dataKey="id"
          responsiveLayout="scroll"
          paginator={true}
          rows={rowsPerPage}
          totalRecords={auditOrderList.length}
          rowsPerPageOptions={rowsPerPageOptions}
          globalFilter={search}
          emptyMessage="No records found"
          responsive={false}
          resizableColumns={true}
          columnResizeMode="fit"
          scrollable={true}
          scrollWidth="auto"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="Item"
            header="Item Name"
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="Quantity"
            header="Qty"
            style={{ width: "20%" }}
          ></Column>
          <Column field="Unit" header="Unit" style={{ width: "20%" }}></Column>
          <Column
            field="Godown"
            header={`Godown${
              filterData.combined === "Combined" ? " (Combined)" : ""
            }`}
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="Action"
            header="Action"
            body={actionTemplate}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>

      {/* Audit Filter */}
      <Dialog
        className="stock-modal"
        open={auditFilterDialog}
        onClose={(e) => handleClose("AuditFilter")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>FILTER </div>

          <div
            className="dialog-close"
            onClick={(e) => handleClose("AuditFilter")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>

        <GridContainer>
          <GridItem xs={6}>
            <div className="input-control select">
              <FormControl fullWidth>
                <label htmlFor="simple-select">Item Name</label>
                <MultiSelect
                  value={filterDialogData.item}
                  onChange={(e) => handleChange(e, "item")}
                  options={itemList}
                  optionLabel="label"
                  placeholder="Item Name"
                  className="w-full md:w-20rem"
                />
              </FormControl>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <div className="input-control select">
              <FormControl fullWidth>
                <label htmlFor="simple-select">Unit</label>
                <MultiSelect
                  value={filterDialogData.unit}
                  onChange={(e) => handleChange(e, "unit")}
                  options={unitList}
                  optionLabel="label"
                  placeholder="Unit Name"
                  className="w-full md:w-20rem"
                />
              </FormControl>
            </div>
          </GridItem>

          <GridItem md={6}>
            <div className="input-control select">
              <FormControl fullWidth>
                <label htmlFor="simple-select">Godown</label>
                <MultiSelect
                  value={filterDialogData.subWareHouse}
                  onChange={(e) => handleChange(e, "subWarehouse")}
                  options={godownList}
                  optionLabel="label"
                  placeholder="Godown"
                  className="w-full md:w-20rem"
                />
              </FormControl>
            </div>
          </GridItem>

          <GridItem item md={12}>
            <div className="p-mt-2">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="godown-mode"
                  name="godown-mode"
                  value={filterDialogData.combined}
                  onChange={(e, value) => handleChange(value, "combined")}
                >
                  <FormControlLabel
                    value="Combined"
                    control={<Radio />}
                    label="Combined"
                  />
                  <FormControlLabel
                    value="Individual"
                    control={<Radio />}
                    label="Individual"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </GridItem>
        </GridContainer>

        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("AuditFilter")}
          >
            Cancel
          </Button>

          <Button className="primary-btn" onClick={handleFilter} type="submit">
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Audit PDF */}
      <Dialog
        className="stock-modal"
        open={auditPDFDialog}
        onClose={(e) => handleClose("AuditPDF")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Audit Number: {generatedAuditNo}</div>

          <div
            className="dialog-close"
            onClick={(e) => handleClose("AuditPDF")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <div className="main-access-card-master">
          <DataTable
            className="main-table table-td"
            value={auditOrderList}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={false}
            totalRecords={auditOrderList.length}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Item"
              header="Item Name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="ActualStock"
              header="Actual"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Unit"
              header="Unit"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Godown"
              header="Godown"
              style={{ width: "20%" }}
            ></Column>
          </DataTable>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              <p>
                Audit Date: <span>{new Date().toLocaleDateString()}</span>
              </p>
            </div>

            <div>
              <button
                className="icon-btn"
                onClick={() => addUpdateAuditItems()}
              >
                <i className="fas fa-file-pdf"></i>
                Generate PDF
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Audit Search */}
      <Dialog
        className="stock-modal"
        open={auditSearchDialog}
        onClose={(e) => handleClose("AuditOrder")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Audit </div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose("AuditOrder")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>

        <GridItem md={12}>
          <TextField
            autoFocus
            margin="dense"
            name="auditNo"
            label="Audit No."
            type="text"
            value={auditNo.name}
            onChange={(e) => handleChange(e, "auditNo")}
            error={auditNo.error}
            helperText={auditNo.errorText}
            fullWidth
          />
        </GridItem>
        <DialogActions className="p-p-0 dialog-footer1">
          <Button className="primary-btn" onClick={getAuditItems} type="submit">
            Search
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="stock-modal"
        open={auditDataDialog}
        onClose={(e) => handleClose("AuditData")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Audit({auditNo.name})</div>

          <div
            className="dialog-close"
            onClick={(e) => handleClose("AuditData")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <div className="main-access-card-master">
          <div>
            Audit Date: {new Date(auditData.CreatedOn).toLocaleDateString()} |
            Current Date: {new Date().toLocaleDateString()}
          </div>
          <DataTable
            className="main-table table-td"
            style={{ height: "auto" }}
            value={auditData.Items}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={false}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Item"
              header="Item Name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="CurrentStock"
              header="Current Stock"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Unit"
              header="Unit"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Godown"
              header="Godown"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="ActualStock"
              header="Actual Stock"
              style={{ width: "20%" }}
              body={(rowData, props) =>
                actionBodyTemplateAuditData(rowData, props, "ActualStock")
              }
            ></Column>
            <Column
              field="Remark"
              header="Remark"
              style={{ width: "20%" }}
              body={(rowData, props) =>
                actionBodyTemplateAuditData(rowData, props, "Remark")
              }
            ></Column>
          </DataTable>

          <div>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                autoFocus
                margin="dense"
                name="remarks"
                label="General Remarks"
                type="text"
                value={auditData.Remark}
                onChange={(e) => handleChange(e, "remark")}
                fullWidth
                multiline
                minRows={2}
              />
            </GridItem>
          </div>

          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn"
              onClick={() => addUpdateAuditItems(auditNo.name, auditData.Items)}
              type="submit"
            >
              Update
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* Order  cart Pop-up */}
      <Dialog
        className="stock-modal"
        open={orderDialog}
        onClose={(e) => handleClose("AuditFilter")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Order </div>

          <div className="dialog-close" onClick={(e) => handleClose("Order")}>
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>

        <GridContainer>
          <GridItem sm={12}>
            <TextField
              autoFocus
              margin="dense"
              name="orderNo"
              label="Order No."
              type="text"
              value={orderNo.name}
              onChange={(e) => handleChange(e, "orderNo")}
              error={orderNo.error}
              helperText={orderNo.errorText}
              fullWidth
            />
          </GridItem>
          <GridItem sm={12}>
            <div>Compare With</div>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="godown-mode"
                name="godown-mode"
                value={compareWith}
                onChange={(e, value) => handleChange(value, "compareOrderWith")}
              >
                <FormControlLabel
                  value="IdealLimit"
                  control={<Radio />}
                  label="Ideal Limit"
                />
                <FormControlLabel
                  value="LowLimit"
                  control={<Radio />}
                  label="Min Limit"
                />
                <FormControlLabel
                  value="HighLimit"
                  control={<Radio />}
                  label="Max Limit"
                />
              </RadioGroup>
            </FormControl>
          </GridItem>
        </GridContainer>

        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn"
            onClick={() => compareOrderData()}
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="stock-modal"
        open={orderScreenDialog}
        onClose={(e) => handleClose("OrderScreen")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Order Number: </div>

          <div
            className="dialog-close"
            onClick={(e) => handleClose("OrderScreen")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <div className="main-access-card-master">
          <DataTable
            className="main-table table-td"
            value={compareData}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={true}
            rows={rowsPerPage}
            totalRecords={compareData.length}
            rowsPerPageOptions={rowsPerPageOptions}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="ItemName"
              header="Item Name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Limit"
              header={compareWith.split("Limit").join(" Limit")}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="CurrentStock"
              header="Current Stock"
              style={{ width: "20%" }}
              body={(rowData) => {
                let difference = rowData.CurrentStock - rowData.Limit;
                if (difference > 0) {
                  return `${rowData.CurrentStock}(+${difference})`;
                } else if (difference == 0) {
                  return `${rowData.CurrentStock}(0)`;
                } else {
                  return `${rowData.CurrentStock}(${difference})`;
                }
              }}
            ></Column>
            <Column
              field="Unit"
              header="Unit"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="Godown"
              header="Godown"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="OrderQuantity"
              header="Order Qty"
              style={{ width: "20%" }}
              body={actionBodyTemplateOrderData}
            ></Column>
          </DataTable>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              <button className="icon-btn" onClick={executeOrder}>
                <i className="fas fa-shopping-cart"></i>
                EXECUTE ORDER
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        className="stock-modal"
        open={editItemDialog}
        onClose={(e) => handleClose("EditMasterType")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Edit Item </div>

          <div
            className="dialog-close"
            onClick={(e) => handleClose("EditMasterType")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>

        <GridContainer>
          <GridItem xs={12}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <Autocomplete
                  id="item"
                  name="item"
                  value={
                    editItem.Item && itemList.length
                      ? itemList.filter((y) => y.value == editItem.Item)[0]
                      : ""
                  }
                  options={itemList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  disabled={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Item Name"
                      label="Item Name"
                      disabled
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>
          <GridItem xs={12}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <Autocomplete
                  id="unit"
                  name="unit"
                  value={
                    editItem.Unit && unitList.length
                      ? unitList.filter((y) => y.value == editItem.Unit)[0]
                      : ""
                  }
                  options={unitList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Unit Name"
                      label="Unit Name"
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <div className="input-control select p-d-flex">
              <FormControl fullWidth>
                <Autocomplete
                  id="godown"
                  name="godown"
                  value={
                    editItem.Godown && godownList.length
                      ? godownList.filter((y) => y.value == editItem.Godown)[0]
                      : ""
                  }
                  options={godownList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Godown"
                      label="Godown"
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>
          <GridItem sm={12}>
            <TextField
              autoFocus
              margin="dense"
              name="Qty"
              label="Quantity*"
              type="text"
              value={editItem.Quantity}
              onChange={(e) => handleChange(e, "qty")}
              inputProps={{ maxLength: 10 }}
              fullWidth
            />
          </GridItem>
        </GridContainer>

        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("EditMasterType")}
          >
            Cancel
          </Button>

          <Button
            className="primary-btn"
            disabled={!editItem.Quantity}
            onClick={handleEditItem}
            type="submit"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AuditOrder;
